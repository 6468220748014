import React, { useState, useEffect } from 'react';
import ReactPaginate from 'react-paginate';
import useBreakpoint from '../../hooks/useBreakpoint';

import styles from './styles.scss';

const Pagination = props => {
  const { pageCount, onPageClick, forcePage } = props;
  const breakpoint = useBreakpoint();
  const [hasBreak, setHasBreak] = useState(false);

  useEffect(() => {
    if (breakpoint === 'mobile') setHasBreak(true);
  }, []);

  useEffect(() => {
    const paginationLinks = document.querySelectorAll(`.${styles.paginationContainer__link}`);

    if (paginationLinks && paginationLinks.length) {
      paginationLinks.forEach(item => {
        const currentText = item.innerText;

        if (currentText.length === 1) {
          item.innerText = `0${currentText}`;
        }

        return item;
      });
    }
  });

  return (
    <ReactPaginate
      previousLabel={null}
      nextLabel={null}
      breakLabel={hasBreak ? '...' : null}
      breakClassName={
        hasBreak
          ? `${styles.paginationContainer__item} ${styles.paginationContainer__item}--break`
          : ''
      }
      pageCount={pageCount}
      forcePage={forcePage}
      marginPagesDisplayed={hasBreak ? 1 : 0}
      pageRangeDisplayed={hasBreak ? 4 : 100}
      onPageChange={data => onPageClick(data)}
      containerClassName={styles.paginationContainer}
      pageClassName={`${styles.paginationContainer__item}`}
      pageLinkClassName={styles.paginationContainer__link}
      activeClassName={`${styles.paginationContainer__item} ${styles.paginationContainer__item}--active`}
    />
  );
};

export default Pagination;
