import { get } from 'helpers/utilities';
import { clamp } from './fp';
import deviceInfo from './deviceInfo';

const preventDefault = (e = window.event) => {
  if (e.touches.length === 1) {
    if (e.preventDefault) {
      e.preventDefault();
    }
    e.stopPropagation();
    e.returnValue = false;
  }
};

// spacebar: 32, pageup: 33, pagedown: 34, end: 35, home: 36
// up: 38 down: 40
// to be added left 37, 39 right
const scrollKeys = { 32: 'down', 33: 'up', 34: 'down', 35: 'down', 36: 'up', 38: 'up', 40: 'down' };

/**
 * Use to listen on event of wheel, touchmove and keydown
 * for events like scrolling down using mousewheel, arrow keys
 * and touch events on mobile
 * @param {options} option
 * @param {function} callback
 */
// TO DO complete the direction X
export const listenOnWheel = ({ maxDeltaTouch, maxDeltaKey = 25 }, callback) => {
  const start = { x: 0, y: 0 };
  const overrideDeltaY = get(window, 'navigator.platform', '').match(/Win/gi);

  const wheel = event => {
    const { deltaY, deltaX } = event;
    const direction = deltaY > 0 ? 'down' : 'up';
    callback({
      event,
      deltaX,
      deltaY: overrideDeltaY ? Math.sign(deltaY) * 50 : deltaY,
      direction
    });
  };

  const keydown = event => {
    const direction = scrollKeys[event.keyCode];
    if (direction) {
      const deltaY = direction === 'up' ? maxDeltaKey * -1 : maxDeltaKey;
      const deltaX = direction === 'left' ? maxDeltaKey * -1 : maxDeltaKey;
      callback({ event, deltaY, deltaX, direction });
    }
  };

  const touchStart = event => {
    start.x = event.touches[0].pageX;
    start.y = event.touches[0].pageY;
  };

  const touchMove = event => {
    const pageXChange = start.x - event.touches[0].pageX;
    const pageYChange = start.y - event.touches[0].pageY;

    start.x = event.touches[0].pageX;
    start.y = event.touches[0].pageY;

    const maxDelta = maxDeltaTouch || Math.abs(pageXChange);

    const deltaX = clamp(maxDelta * -1, maxDelta)(pageXChange);
    const deltaY = clamp(maxDelta * -1, maxDelta)(pageYChange);

    const direction = deltaY > 0 ? 'down' : 'up';

    callback({ event, deltaY, deltaX, direction });
  };

  // wheel event for most of the case
  window.onmousewheel = wheel;
  window.onwheel = wheel;

  document.addEventListener('wheel', wheel, { passive: false });
  document.onmousewheel = wheel;

  // in case for key event
  document.onkeydown = keydown;

  // in case for touch event no delta XY
  document.addEventListener('touchmove', touchMove, false);
  document.addEventListener('touchstart', touchStart, false);

  return () => {
    window.onmousewheel = null;
    window.onwheel = null;

    document.removeEventListener('wheel', wheel, { passive: false });
    document.onmousewheel = null;

    document.onkeydown = null;

    document.removeEventListener('touchmove', touchMove, false);
    document.removeEventListener('touchstart', touchStart, false);
  };
};

let bodyScrollDisabled = false;

export const disableBodyScroll = ({ retainScrollBar = true, overlay = false } = {}) => {
  bodyScrollDisabled = true;
  if (deviceInfo.isTouch && !overlay) {
    document.addEventListener('touchmove', preventDefault, { passive: false });
  }
  const style = { overflow: 'hidden' };
  const scrollBarGap = window.innerWidth - document.documentElement.clientWidth;
  if (retainScrollBar && scrollBarGap > 0) {
    Object.assign(style, { paddingRight: `${scrollBarGap}px` });
  }

  Object.assign(document.body.style, style);
};

export const enableBodyScroll = ({ overlay = false } = {}) => {
  bodyScrollDisabled = false;
  if (deviceInfo.isTouch && !overlay) {
    document.removeEventListener('touchmove', preventDefault, { passive: false });
  }
  const style = { overflow: '', paddingRight: '0px' };
  Object.assign(document.body.style, style);
};

export const isBodyScrollDisabled = () => bodyScrollDisabled;

export const scrollTo = ({ top, behavior }, callback, scrollableElement = window) => {
  const onScroll = () => {
    if (scrollableElement.pageYOffset === top || scrollableElement.scrollTop === top) {
      scrollableElement.removeEventListener('scroll', onScroll);
      callback();
    }
  };
  scrollableElement.addEventListener('scroll', onScroll);
  onScroll();
  scrollableElement.scrollTo({
    top,
    behavior
  });
};

export const recursiveScrollTo = (element, distance, offset, done) => {
  window.requestAnimationFrame(() => {
    const top = Math.trunc(element.getBoundingClientRect().top) + 3 + window.pageYOffset + offset;

    const scrollTop =
      top > window.pageYOffset
        ? clamp(0, top)(window.pageYOffset + distance)
        : clamp(top, Infinity)(window.pageYOffset - distance);
    window.scrollTo({ top: scrollTop });

    if (scrollTop === top) done();
    else recursiveScrollTo(element, distance, offset, done);
  });
};

const easeIn = power => t => t ** power;
const easeOut = power => dep => 1 - Math.abs((dep - 1) ** power);

const easeInOut = power => t =>
  t < 0.5 ? easeIn(power)(t * 2) / 2 : easeOut(power)(t * 2 - 1) / 2 + 0.5;

const easeInOutQuad = easeInOut(2);

export const animateScrollTo = (targetScroll, done) => {
  // eslint-disable-next-line prefer-destructuring
  const pageYOffset = window.pageYOffset;
  const displacement = targetScroll - pageYOffset;
  const duration = Math.abs(displacement > 5000 ? 1 : displacement / 5000) * 1000; // 1s maximum at 5000px,

  let start = 0;
  let current = 0;
  let progress = 0;

  if (!displacement) return;

  const step = time => {
    start = start || time;
    current = time - start;
    progress = Number(easeInOutQuad(clamp(0, 1)(current / duration)).toFixed(2));

    window.scrollTo(0, progress * displacement + pageYOffset);

    if (progress !== 1) window.requestAnimationFrame(step);
    else done && done();
  };

  window.requestAnimationFrame(step);
};
