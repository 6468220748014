import React from 'react';
import { Link } from 'react-router-dom';
import Meta from 'components/Meta';
import styles from './styles.scss';

const ClientError = ({ error }) => (
  <>
    <Meta active={true} />
    <section className={styles['error-container']}>
      <div className={`${styles['error-container__content-container']}`}>
        <h1 className={`${styles['error-container__content-container__title']}`}>Oops!</h1>
        <aside className={`${styles['error-container__content-container__error-details']}`}>
          <p>
            <strong>Something went wrong.</strong>
          </p>
          <Link to="/" title="Back to Homepage">
            Back to Homepage
          </Link>
        </aside>
      </div>
      <code style={{ display: 'none', color: '#ffffff' }}>{error}</code>
    </section>
  </>
);

export default ClientError;
