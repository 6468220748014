import React, { createContext, useEffect, useMemo, useRef, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import canUseDOM from '../helpers/canUseDOM';

const ScrollRestoration = createContext({
  setScrollOffset: () => {},
  pushHistoryKey: () => {},
  addPrevLocationKey: () => {}
});

export const ScrollRestorationProvider = ({ children }) => {
  const history = useHistory();
  const scrollHistory = useRef(new Map());

  const currentLocationKey = useRef();
  const scrollOffset = useRef(0); // use for special cases like transporting

  // use a ref for previous for manual history listen
  const prevLocationKeys = useRef([]);

  const pushHistoryKey = useCallback((key, fromStart) => {
    scrollHistory.current.set(key, fromStart ? 0 : window.scrollY - scrollOffset.current);
    scrollOffset.current = 0;
  }, []);

  // mainly use by transporter
  const addPrevLocationKey = useCallback(key => {
    prevLocationKeys.current.push(key);
  }, []);

  const value = useMemo(
    () => ({
      setScrollOffset: offset => {
        scrollOffset.current = offset;
      },
      pushHistoryKey,
      addPrevLocationKey,
      currentLocationKey
    }),
    []
  );

  useEffect(() => {
    currentLocationKey.current = history.location.key;
    if (canUseDOM && 'scrollRestoration' in window.history) {
      history.scrollRestoration = 'manual';
      window.history.scrollRestoration = 'manual';
    }
    window.scroll(0, 0);
    // listen for location change
    return history.listen(location => {
      if (history.action === 'PUSH') {
        // push history
        pushHistoryKey(currentLocationKey.current);
        currentLocationKey.current = location.key;
        window.scroll(0, 0);
      } else if (history.action === 'POP' && location.key) {
        // pop history of react router
        const scrollY = scrollHistory.current.get(location.key);
        currentLocationKey.current = location.key;
        window.requestAnimationFrame(() => window.scroll(0, scrollY));
      } else if (history.action === 'POP' && !location.key && !location.hash) {
        // pop history or non react router
        const prevLocationKey = prevLocationKeys.current.pop();
        const scrollY = scrollHistory.current.get(prevLocationKey);
        currentLocationKey.current = prevLocationKey;
        window.requestAnimationFrame(() => window.scroll(0, scrollY));
      }
    });
  }, []);

  return <ScrollRestoration.Provider value={value}>{children}</ScrollRestoration.Provider>;
};

export default ScrollRestoration;
