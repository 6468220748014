const metaDetails = `
    socialMetaSection {
        ... on SocialMetaSectionMetaFields {
            metaTitle
            metaDescription
            metaImage {
                    url
                }
            metaKeywords
        }
    }
`;

export default metaDetails;
