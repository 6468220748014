import React from 'react';
import styles from './styles.scss';

const LoadingDots = ({ className, size = '10px', color, style }) => (
  <div className={`${styles.loadingDots} ${className}`} style={style}>
    <span style={{ width: size, height: size, backgroundColor: color }} />
    <span style={{ width: size, height: size, backgroundColor: color }} />
    <span style={{ width: size, height: size, backgroundColor: color }} />
  </div>
);

export default LoadingDots;
