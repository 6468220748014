import React, { Fragment, useState, useRef, useEffect } from 'react';
import Video from 'components/Video';
import Image from 'components/Image';
import LazyLoad from 'react-lazyload';
import { safeRender } from 'helpers/layout';
import styles from './styles.scss';

const CarouseVideo = ({
  imageURL,
  imageAlt,
  videoURL,
  videoClass,
  imageClass,
  imageSizes,
  grayscale = false,
  playablePercentage = 0.05
}) => {
  const [videoLoaded, setVideoLoaded] = useState(false);
  // TO DO
  // temporary use a ref to hold the state of videoLoaded
  // used on onProgress of Video component due to value
  // not updating inside of onProgress function
  const videoLoadedRef = useRef(false);
  const videoRef = useRef();

  useEffect(() => setVideoLoaded(false), [videoURL]);

  return (
    <>
      {safeRender(
        videoURL,
        <LazyLoad height="100%" offset={1000} once>
          <Video
            autoPlay={true}
            muted={true}
            loop={true}
            src={videoURL}
            togglePlay={true}
            className={videoClass}
            videoRef={videoRef}
            onProgress={event => {
              if (
                (event.loaded >= playablePercentage || event.loadedSeconds >= 5) &&
                !videoLoadedRef.current
              ) {
                videoLoadedRef.current = true;
                setVideoLoaded(true);

                // move the current second to start
                const { current } = videoRef;
                if (current) current.seekTo(0);
              }
            }}
          />
        </LazyLoad>
      )}
      <Image
        sizes={imageSizes}
        src={imageURL}
        ariaHidden
        lazyload={false}
        alt={imageAlt}
        grayscale={grayscale}
        className={`${imageClass} ${styles.carouselVideo__image} ${
          !videoLoaded || !videoURL ? '' : styles.carouselVideo__image_hidden
        }`}
      />
    </>
  );
};

export default CarouseVideo;
