import React, { useState, useCallback, useRef, useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { get, isEmpty, clamp } from 'helpers/utilities';
import * as ScrollMagic from 'scrollmagic-with-ssr';
import { TimelineMax, TweenMax } from 'gsap';
import { SlowMo } from 'gsap/dist/EasePack';
import { EVENT } from 'config/constants';
import EventHandler from 'helpers/EventHandler';
import deviceInfo from 'helpers/deviceInfo';
import Icon from 'components/Icon';
import { getThrottledRAF } from 'helpers/layout';
import getPageType from 'helpers/getPageType';
import transporterLogic from './transporterLogic';
// hooks
import useNavigation from '../../hooks/useNavigation';
import useScrollRestoration from '../../hooks/useScrollRestoration';
import usePrevious from '../../hooks/usePrevious';
import useAccessibility from '../../hooks/useAccessibility';

const MAX_TRANSPORTER_PAGES = 3; // 3 pages total - initial page plus the two we transport to.

const nonTransporterPages = ['newBusiness', 'info', 'jobDetail'];

/**
 * Builds an array of pages based on transporterLogic.
 *
 * @return {array} An array of page objects.
 * page: {
 *    id: string;
 *    loaded: boolean;
 *    active: boolean;
 *    nextTemplate: string;
 *    nextTemplateUrl: string;
 *    pageTitle: string;
 *    url: string;
 * }
 */
const getTransporterPages = (type, initialUrl) => {
  const pages = [];

  // If templateType is one of the non transporter pages return pages
  if (nonTransporterPages.some(pageType => pageType === type)) return pages;
  // Store initial template in array.
  const initialTemplate = transporterLogic[type];
  if (!initialTemplate) return pages;
  initialTemplate.id = type;
  initialTemplate.url = initialUrl;
  initialTemplate.loaded = true;
  initialTemplate.active = true;
  pages.push(initialTemplate);

  // Store subsequent templates in array.
  let i = 0;
  let currTemplate = transporterLogic[type];

  // Why We Make needs to go all the way to About Us. So it gets 3 transports, rather than 2.
  const maxTransporterPages = type === 'whyWeMake' ? 3 : MAX_TRANSPORTER_PAGES;
  for (i; i < maxTransporterPages; i++) {
    // Get id from previous object.
    const id = currTemplate.nextTemplate;
    // Update currTemplate.
    currTemplate = transporterLogic[currTemplate.nextTemplate];
    // Store id and set loaded and active to false.
    // The Rest of the data is 1 to 1 of transporterLogic object.
    currTemplate.id = id;
    currTemplate.loaded = false;
    currTemplate.active = false;

    // Loop through pages already store to determine to prevent duplicate transporter pages.
    let exists = false;
    let j = 0;
    for (j; j < pages.length; j++) {
      if (pages[j].pageTitle === currTemplate.pageTitle) {
        exists = true;
      }
    }

    if (!exists) {
      pages.push(currTemplate);
    }
  }

  return pages;
};

/**
 * Calculates the combined scroll height of all transporter pages before the current active.
 * This will be used to reduce the current scroll value
 * when changing routes from transporter to contact or search.
 *
 * @param {number} index The index of the current active page
 * @return {number} The total scroll height of previous transporter pages.
 */
const getCombinedPagesScrollHeight = activePageIndex => {
  const pages = document.getElementsByClassName('page-transporter');
  let combinedScrollHeight = 0;

  if (pages.length) {
    const previousTransporterPages = Array.from(pages).filter(
      (page, pageIndex) => pageIndex < activePageIndex
    );

    if (previousTransporterPages.length > 0) {
      combinedScrollHeight = previousTransporterPages.reduce(
        (accu, curr) => accu + curr.scrollHeight,
        0
      );
    }
  }

  return combinedScrollHeight;
};

const Transporter = ({ children, isSSR }) => {
  const isMobile = deviceInfo.isTouch && (deviceInfo.isIOS || deviceInfo.isAndroid);

  const { isClientPassingThroughLinks } = useAccessibility();
  const { setNavActiveLink } = useNavigation();
  const { setScrollOffset, pushHistoryKey, addPrevLocationKey, currentLocationKey } =
    useScrollRestoration();
  const history = useHistory();
  const transporterTLs = useRef([]);
  const pageNameTLs = useRef([]);
  const titleTweens = useRef([]);
  const scrollMagicController = useRef();
  const location = useLocation();
  const previousLocation = usePrevious(location);
  const initialUrl = location.pathname;

  const isFilter =
    get(location, 'pathname').substr(1).split('/').length > 2 &&
    get(location, 'pathname').substr(1).split('/')[1] === 'filter';
  const templateType = getPageType(get(location, 'pathname'));

  const [activePageIndex, setActivePageIndex] = useState(0);
  const [transporterPages, setTransporterPages] = useState(
    getTransporterPages(templateType, initialUrl)
  );

  // hotfx for component will update implementation of Transporter class based
  if (previousLocation && location.pathname !== previousLocation.pathname) {
    scrollMagicController.current.destroy(true);
    scrollMagicController.current = new ScrollMagic.Controller();
  }

  /**
   * Calculates active page height.
   * Using state.activePageIndex, loop through DOM with class 'page-transporter'.
   *
   * @return {number} The height of the page.
   */
  const getActivePageHeight = useCallback(() => {
    let height = 0;
    const pages = document.getElementsByClassName('page-transporter');

    if (activePageIndex > 0) {
      let i = 0;
      for (i; i <= activePageIndex; i++) {
        const page = pages[i];
        if (page) {
          height += page.clientHeight;
        }
      }
    } else {
      height = pages[0].clientHeight;
    }

    return height;
  }, [activePageIndex]);

  /**
   * Updates page URL.
   *
   * @param {string} url The url we would like to update to.
   * @return {void}
   */
  const updateUrl = useCallback(url => {
    setNavActiveLink(url);
    // push the current location key and set the scroll from start or 0.
    pushHistoryKey(currentLocationKey.current, true);
    currentLocationKey.current = Symbol(url);
    // add a non react router location key;
    addPrevLocationKey(currentLocationKey.current);
    window.history.pushState('', '', url);
  }, []);

  /**
   * Updates activePageIndex, then calls updateUrl() as a callback to setState.
   *
   * @param {number} index The page index to make active.
   * @return {void}
   */
  const updateActivePageIndex = useCallback(
    index => {
      if (index !== activePageIndex) {
        setActivePageIndex(index);

        if (index === 0) {
          updateUrl(initialUrl);
        } else if (transporterPages[index]) {
          updateUrl(transporterPages[index].url);
        }
      }
    },
    [initialUrl, activePageIndex, JSON.stringify(transporterPages), updateUrl]
  );

  /**
   * Constructs a greensock timeline to control overall transition,
   *  two tween to cover the title animation in and out,
   *  and another timeline to control the page title swap.
   *
   * Each tween or timeline is pushed into an array, so we can clear them out in reset().
   *
   * @param {number} index The index number of the page associated with the animations.
   * @return {void}
   */
  const addTL = useCallback(
    index => {
      const templateId = transporterPages[index].id;
      const targetBanner = document.getElementById(`transporter-banner--${templateId}`);
      const nextTemplateId = transporterLogic[templateId].nextTemplate;
      const nextPage = document.getElementById(`page-${nextTemplateId}`);

      if (!isEmpty(transporterTLs.current[index]) || isEmpty(targetBanner) || isEmpty(nextPage))
        return;

      // Add active class to targetBanner.
      targetBanner.classList.add('transporter-banner--active');

      const directionText = document.getElementById(`page-direction--${templateId}`);
      const prevPageNameWidth = document.getElementById(
        `transporter-banner-prev-pagename--${templateId}`
      ).clientWidth;

      // Title animation in tween.
      const titleInTween = TweenMax.from(`#transporter-banner--${templateId} .cta`, 0.5, {
        x: 200,
        autoAlpha: 0,
        paused: true,
        force3D: true
      });
      const titleOutTween = TweenMax.to(`#transporter-banner--${templateId} .cta`, 0.25, {
        y: -100,
        paused: true,
        force3D: true
      });

      // Add ScrollMagic for pinning.
      const scrollMagicScene = new ScrollMagic.Scene({
        duration: window.innerHeight,
        triggerElement: nextPage,
        triggerHook: 'onEnter'
      })
        .setPin(nextPage)
        .addTo(scrollMagicController.current);

      // The transform applied to the transporter-pages makes it so scrollmagic calculates its height wrong.
      // So, we need to adjust it.
      scrollMagicScene.on('end', evt => {
        const { state } = evt;
        if (state === 'AFTER') {
          // update transported pages height, except index 0 which is the initial page
          window.requestAnimationFrame(() => {
            const pinDOM = nextPage.closest('.scrollmagic-pin-spacer');
            const pinInlineHeight = pinDOM.style.minHeight;
            const adjustedHeight = parseInt(pinInlineHeight, 10) - window.innerHeight;

            pinDOM.style.minHeight = `${adjustedHeight}px`;
            pinDOM.style.height = `${adjustedHeight}px`;
          });
        }
      });

      // Page title swap timeline. Handles transitioning name and flipping arrow.
      const pageNameTL = new TimelineMax({ paused: true });
      pageNameTL.to(
        `#transporter-banner-page-name--${templateId}`,
        0.5,
        {
          y: '-200%',
          width: prevPageNameWidth
        },
        0
      );
      pageNameTL.to(
        `#transporter-banner--${templateId} .arrow`,
        0.5,
        {
          scale: -1
        },
        0
      );

      const pageNameColorTl = new TimelineMax({ paused: true });
      // Title and arrow color tween.
      pageNameColorTl.to(`#transporter-banner--${templateId} .title`, 0.25, { color: 'white' }, 0);
      pageNameColorTl.to(`#transporter-banner--${templateId} .arrow`, 0.25, { fill: 'white' }, 0);

      // Main transporter timeline.
      // Controls background opacity, next page transition and title color change.
      // Calls title animation tween and page title timeline.
      const tl = new TimelineMax({ paused: true });
      tl.to(
        `#transporter-banner-bg--${templateId}`,
        1,
        {
          // autoAlpha: 0,
          force3D: true,
          ease: SlowMo.ease.config(0.3, 0.4, false),
          onStart: () => {
            // Make next page visible.
            nextPage.classList.add('page-transporter--active');
            titleInTween.play();
          },
          onUpdate: () => {
            // Handle swapping page name.
            if (!tl.reversed() && pageNameTL.progress() === 1) {
              directionText.innerHTML = 'Continue to';
              pageNameTL.reverse();
            } else if (tl.reversed() && pageNameTL.progress() === 0) {
              directionText.innerHTML = 'Back to';
              pageNameTL.play();
            }

            // Handle color swap.
            if (!tl.reversed() && tl.progress() >= 0.5 && pageNameColorTl.progress() === 0) {
              pageNameColorTl.play(0);
            } else if (tl.reversed && tl.progress() <= 0.5 && pageNameColorTl.progress() === 1) {
              pageNameColorTl.reverse(1);
            }

            // Handle toggling pointer events on banner back.
            // This would be onReverseStart, if that existed.
            if (tl.reversed() && targetBanner.style.pointerEvents === 'none') {
              targetBanner.style.pointerEvents = 'auto';
            }

            // Acts as reverseStart.
            if (tl.reversed() && titleOutTween !== 0) {
              titleOutTween.reverse();
            }
          },
          onComplete: () => {
            titleOutTween.play(0);
            targetBanner.style.pointerEvents = 'none';
          },
          onReverseComplete: () => {
            // Remove active class from next page. We have moved back to the previous one.
            nextPage.classList.remove('page-transporter--active');
          }
        },
        0
      );

      // Store everything in the correct arrays.
      transporterTLs.current.push(tl);
      pageNameTLs.current.push(pageNameTL);
      titleTweens.current.push(titleInTween);
      titleTweens.current.push(titleOutTween);
    },
    [JSON.stringify(transporterPages)]
  );

  /**
   * Fully kill and clear timelines and tweens. Empty arrays.
   *
   * @return {void}
   */
  const reset = useCallback(() => {
    const newTransporterPages = getTransporterPages(templateType, initialUrl);

    const killAndInvalidate = el => {
      if (el) {
        el.kill();
        el.invalidate();
        el = null;
      }
    };

    setTransporterPages(newTransporterPages);

    transporterTLs.current.forEach(tl => killAndInvalidate(tl));
    pageNameTLs.current.forEach(tl => killAndInvalidate(tl));
    titleTweens.current.forEach(tween => killAndInvalidate(tween));

    transporterTLs.current = [];
    pageNameTLs.current = [];
    titleTweens.current = [];
  }, [templateType, setTransporterPages]);

  /**
   * Transporter logic. Called from scrollHandler.
   * Pass percent scrolled to associated Timeline.
   * When scroll is beyond the transporter banner makes sure progress is set to 0 (down) or 1 (up),
   * this makes sure timelines are fully complete or started over.
   *
   * @param {number} scrollBottom The calculated value of the current scrollbottom.
   * @param {string} direction Either 'up' or 'down'. Indicates scroll/swipe direction.
   *      'up' is going down the page, 'down' is going up the page.
   * @return {void}
   */
  const handleTransporting = useCallback(
    (scrollBottom, direction) => {
      const pages = document.getElementsByClassName('page-transporter');
      const activePage = pages[activePageIndex];
      const timeline = transporterTLs.current[activePageIndex];

      // Sanity check.
      if (!activePage || !timeline) return;

      const activePageHeight = getActivePageHeight();
      const scrollPercent = parseFloat(
        clamp((scrollBottom - activePageHeight) / window.innerHeight, 0, 1)
      ).toFixed(2);

      if (direction === 'down') {
        // If scrollBottom is within the transporter banner - update the associated timeline.
        // Else if scrollBottom is above the transporter banner and timeline progress is not zero, zero it out.
        if (
          scrollBottom < activePageHeight + window.innerHeight &&
          scrollBottom > activePageHeight
        ) {
          // Going up the page, set reversed to true.
          timeline.reversed(true);
          timeline.progress(scrollPercent);
        } else if (
          timeline.progress() !== 0 &&
          scrollBottom < activePageHeight + window.innerHeight
        ) {
          timeline.progress(0);
        }
      } else if (direction === 'up') {
        // If scroll position is within the transporter banner - update the associated timeline.
        // Else if scrollBottom is passed or at the bottom of the banner and progress is not 1, set it to 1.
        if (
          scrollBottom > activePageHeight &&
          scrollBottom < activePageHeight + window.innerHeight
        ) {
          // Going down the page, set reverse to false.
          timeline.reversed(false);
          timeline.progress(scrollPercent);
        } else if (
          timeline.progress() !== 1 &&
          scrollBottom >= activePageHeight + window.innerHeight
        ) {
          timeline.progress(1);
        }
      }
    },
    [activePageIndex, getActivePageHeight]
  );

  /**
   * Logic used to update activePageIndex.
   * Loops through currently present pages and tests that page's position.
   * If there is a change call updateActivePageIndex passing in new activePageIndex.
   *
   * @param {string} direction Either 'up' or 'down'. Indicates scroll/swipe direction.
   *      'up' is going down the page, 'down' is going up the page.
   * @return {void}
   */
  const monitorActivePage = useCallback(
    (direction, pages) => {
      let updatedActivePageIndex = 0;
      let lastActivePageTimeline = null;

      if (activePageIndex > 0) {
        lastActivePageTimeline = transporterTLs.current[activePageIndex - 1];
      }
      Array.from(pages).forEach((page, index) => {
        if (direction === 'down' && page.getBoundingClientRect().top < 0) {
          updatedActivePageIndex = index;
        } else if (direction === 'up' && page.getBoundingClientRect().bottom < 1) {
          updatedActivePageIndex = index + 1;
          // Update the previous active page timeline to 1
          // before updating to next page to active.
          if (lastActivePageTimeline && lastActivePageTimeline.progress() !== 1) {
            lastActivePageTimeline.progress(1);
          }
        }
      });
      if (activePageIndex !== updatedActivePageIndex) {
        updateActivePageIndex(updatedActivePageIndex);
      }
    },
    [activePageIndex, updateActivePageIndex]
  );

  /**
   * Logic used to decide when to load next page.
   * Should only be called when direction coming from scrollHandler is 'up',
   * because that is when we are going down the page.
   *
   * @param {number} scrollBottom The calculated value of the current scrollbottom.
   */
  const handlePageLoads = useCallback(
    scrollBottom => {
      const buffer = window.innerHeight; // How far from the bottom of the current page to load the next page.
      const pages = document.getElementsByClassName('page-transporter');
      const activePage = pages[activePageIndex];

      if (!activePage) return;
      const activePageHeight = getActivePageHeight();

      // if all have been loaded / the last item has been loaded, return;
      if (transporterPages[transporterPages.length - 1]?.loaded) return;

      // If we're at the bottom (minus buffer) and next page has NOT been loaded - load it.
      if (
        scrollBottom >= activePageHeight - buffer &&
        !transporterPages[activePageIndex + 1]?.loaded
      ) {
        setTransporterPages(
          transporterPages.map((transporterPage, index) => {
            if (index === activePageIndex + 1) return { ...transporterPage, loaded: true };
            return transporterPage;
          })
        );
        addTL(activePageIndex);

        // Re-initialize lazysizes.
        if (window.lazySizes) {
          window.lazySizes.init();
        }
      }
    },
    [
      activePageIndex,
      JSON.stringify(transporterPages),
      getActivePageHeight,
      setTransporterPages,
      addTL
    ]
  );

  /**
   * ScrollHandler - called on scroll.
   *
   * @param {string} type The type of event - here it is 'EVENT/SCROLL'.
   * @param {object} e Scroll event data.
   * e: {
   *  direction: 'up' | 'down',
   *  scrollY: number,
   *  time: number,
   * }
   */
  const scrollHandler = useCallback(
    (type, e, pages) => {
      const { direction, scrollY } = e;
      const scrollBottom = scrollY + window.innerHeight;

      if (isEmpty(transporterPages)) return;

      // If direction is up - content can be loaded.
      if (direction === 'up') {
        handlePageLoads(scrollBottom);
      }

      handleTransporting(scrollBottom, direction);
      monitorActivePage(direction, pages);
    },
    [JSON.stringify(transporterPages), handlePageLoads, handleTransporting, monitorActivePage]
  );

  const onTransporterLinkFocus = useCallback(() => {
    if (isEmpty(transporterPages)) return;
    scrollMagicController.current.destroy(true);
    const pages = [...document.getElementsByClassName('page-transporter')];
    pages.forEach(page => page.removeAttribute('style'));
  }, [transporterPages]);

  const onWheelScroll = () => {
    scrollMagicController.current.destroy(true);
    scrollMagicController.current = new ScrollMagic.Controller();
  };

  const onKeyScroll = e => {
    const keyCode = e.code;
    if (
      keyCode === 'Home' ||
      keyCode === 'End' ||
      keyCode === 'PageUp' ||
      keyCode === 'PageDown' ||
      keyCode === 'ArrowUp' ||
      keyCode === 'ArrowDown'
    ) {
      onWheelScroll();
    }
  };

  useEffect(() => {
    const transporterLinks = document.querySelectorAll(
      '.transporter-banner__title:not(.transporter-banner__title--hidden)'
    );

    transporterLinks.forEach(link => {
      link.addEventListener('focus', onTransporterLinkFocus);
    });

    if (isClientPassingThroughLinks) {
      window.addEventListener('wheel', onWheelScroll);
      window.addEventListener('keyup', onKeyScroll);

      return () => {
        window.removeEventListener('keyup', onKeyScroll);
        window.removeEventListener('wheel', onWheelScroll);
      };
    }

    return () => {
      transporterLinks.forEach(link => {
        link.removeEventListener('focus', onTransporterLinkFocus);
      });
    };
  }, [isClientPassingThroughLinks, onTransporterLinkFocus]);

  useEffect(() => {
    scrollMagicController.current = new ScrollMagic.Controller();
    // // replacing the component will update by history listen
    // // removing scrollmagiccontroller on did update throws a 500 error
    // // when transporting
    history.listen(() => {
      scrollMagicController.current.destroy(true);
      scrollMagicController.current = new ScrollMagic.Controller();
      setActivePageIndex(0);
      setTransporterPages([]);
    });

    // Apply the scroll to top workaround to pages other than info.
    if (templateType !== 'info') {
      // Forces window to top on refresh.
      window.onbeforeunload = () => {
        // Mailto and Tel links triggers unload event as well,
        // So we have to work around it to prevent them from scrolling back to top
        if (!window.ignore_unload) {
          window.scrollTo(0, 0);
        }

        window.ignore_unload = true;
      };
    }
  }, []);

  useEffect(() => {
    if (isEmpty(transporterPages)) reset();
  }, [templateType, JSON.stringify(transporterPages)]);

  useEffect(() => {
    const throttledRAF = getThrottledRAF();
    const pages = document.getElementsByClassName('page-transporter');
    const scrollEvent = EventHandler.subscribe(EVENT.SCROLL, (...args) => {
      throttledRAF(() => {
        scrollHandler(...args, pages);
      });
    });

    return () => EventHandler.unsubscribe(scrollEvent);
  }, [scrollHandler]);

  useEffect(() => {
    const combinedScrollHeight = getCombinedPagesScrollHeight(activePageIndex);
    if (activePageIndex > 0 && combinedScrollHeight > 0) {
      const computedScrollValue = combinedScrollHeight - window.innerHeight * 2;
      const scrollTopAdjustment = computedScrollValue > 0 ? computedScrollValue : 0;
      setScrollOffset(scrollTopAdjustment);
    }
  }, [activePageIndex]);

  useEffect(() => {
    if (isMobile) {
      const prevTemplateId = transporterPages[activePageIndex]
        ? transporterPages[activePageIndex].id
        : undefined;
      if (prevTemplateId) {
        const prevBanner = document.getElementById(`transporter-banner--${prevTemplateId}`);
        if (prevBanner) prevBanner.style.pointerEvents = 'auto';
      }
    }
  }, [activePageIndex, JSON.stringify(transporterPages), isMobile]);

  const intitialNextTitle = transporterPages.length > 0 ? transporterPages[1].pageTitle : undefined;
  const intitialPrevTitle = transporterPages.length > 0 ? transporterPages[0].pageTitle : undefined;
  const nextTemplateUrl =
    transporterPages.length > 0 ? transporterPages[0].nextTemplateUrl : undefined;
  const prevTemplateUrl = transporterPages.length > 0 ? transporterPages[0].url : undefined;

  return useMemo(
    () => (
      <>
        <article
          id={`page-${!isFilter ? templateType : 'filter'}`}
          className={`page page-${
            !isFilter ? templateType : 'filter'
          } page-transporter page-transporter--active ${isSSR ? 'page--inactive' : ''}`}
          style={{ zIndex: 1 }}>
          {children({ active: activePageIndex === 0 })}
          {transporterPages.length > 0 && (
            <Banner
              templateId={templateType}
              nextPageName={intitialNextTitle}
              prevPagename={intitialPrevTitle}
              nextTemplate={nextTemplateUrl}
              prevTemplate={prevTemplateUrl}
            />
          )}
        </article>
        {transporterPages.map((page, index) => {
          // Transporter pages are fixed unless the index is higher than activePageIndex.
          if (page.id !== templateType && index !== 0 && index < transporterPages.length) {
            return (
              <TransporterPage
                page={page}
                index={index}
                activePageIndex={activePageIndex}
                transporterPages={transporterPages}
                key={index}
              />
            );
          }
          return null;
        })}
      </>
    ),
    [
      isFilter,
      templateType,
      activePageIndex,
      JSON.stringify(transporterPages),
      intitialNextTitle,
      intitialPrevTitle
    ]
  );
};

const TransporterPage = ({ page, index, activePageIndex, transporterPages }) => {
  const transporterHeadRef = useRef();
  const { id, NextComponent: Page } = page;

  useEffect(() => {
    const listener = () => {
      transporterHeadRef.current.style.transform = `translate3d(0, calc(${
        window.innerHeight - 1
      }px * -1), 0)`;
    };

    listener();

    if (deviceInfo.isMobile) {
      window.addEventListener('resize', listener);
    }

    return () => {
      window.removeEventListener('resize', listener);
    };
  }, []);

  if (!Page) return null;

  return (
    <article
      ref={transporterHeadRef}
      id={`page-${id}`}
      key={index}
      className={`page page-transporter page-${id}`}
      style={{ zIndex: activePageIndex === index ? 1 : -1 }}>
      <Page transporterHeadRef={transporterHeadRef} active={index === activePageIndex} />
      {index < transporterPages.length - 1 && (
        <Banner
          templateId={id}
          nextPageName={transporterPages[index + 1].pageTitle}
          prevPagename={transporterPages[index].pageTitle}
          nextTemplate={transporterPages[index].nextTemplateUrl}
          prevTemplate={transporterPages[index].url}
        />
      )}
    </article>
  );
};

const Banner = ({ templateId, nextPageName, prevPagename, nextTemplate, prevTemplate }) => (
  <div id={`transporter-banner--${templateId}`} className="transporter-banner">
    <div
      id={`transporter-banner-bg--${templateId}`}
      className="transporter-banner__bg background"
    />
    <div className="transporter-banner__cta cta">
      <a
        href={nextTemplate}
        id={`transporter-banner-title--${templateId}`}
        className="transporter-banner__title title">
        <span id={`page-direction--${templateId}`}>Continue to</span>
        <span
          id={`transporter-banner-page-name--${templateId}`}
          className="transporter-banner__page-name page-name">
          <span className="transporter-banner__page-name--next">{nextPageName}</span>
          <span
            id={`transporter-banner-prev-pagename--${templateId}`}
            className="transporter-banner__page-name--prev">
            {prevPagename}
          </span>
        </span>
      </a>
      <a
        href={prevTemplate}
        id={`transporter-hidden-banner-title--${templateId}`}
        className="transporter-banner__title transporter-banner__title--hidden title-secondary"
        aria-hidden="true">
        <span>Back to</span>
        <span className="transporter-banner__page-name">{prevPagename}</span>
      </a>
      <Icon
        name="chevronThickDown"
        size="small"
        color="black"
        classes="transporter-banner__arrow arrow"
      />
    </div>
  </div>
);

export default Transporter;
