import { get, upperFirst } from 'helpers/utilities';

const articleCommonFields = `
    videoType
    articleTitle {
        content
    }
    imageBackground {
        url
        title
    }
    videoBackgroundDesktop {
        url
        title
    }
    videoAssetBackgroundDesktop {
        url
        title
    }
    videoBackgroundTablet {
        url
        title
    }
    videoAssetBackgroundTablet {
        url
        title
    }
    videoBackgroundMobile {
        url
        title
    }
    videoAssetBackgroundMobile {
        url
        title
    }
`;

const articleHeaderNoEyebrow = `
    articleHeaderNoEyebrow {
        ... on ArticleHeaderNoEyebrowHeaderNoEyebrow {
            ${articleCommonFields}
            ar16by9
        }
    }
`;

const articleHeaderRequiredEyebrow = `
    articleHeaderRequiredEyebrow {
        ... on ArticleHeaderRequiredEyebrowHeaderRequiredEyebrow {
            eyebrow
            ${articleCommonFields}
        }
    }
`;

const articleHeaderOptionalEyebrow = `
    articleHeaderOptionalEyebrow {
        ... on ArticleHeaderOptionalEyebrowHeaderOptionalEyebrow {
            eyebrow
            ${articleCommonFields}
        }
    }
`;

const articleHeaderTitleOnly = `
    articleHeaderTitleOnly {
        ... on ArticleHeaderTitleOnlyHeaderTitleOnly {
            articleTitle {
                content
            }
        }
    }
`;

// quick fixed for article header picking of content
// should implement mapping or is this fine?
const pickArticleHeader = prop => data =>
  get(data, `articleHeaderNoEyebrow[0].${prop}`) ||
  get(data, `articleHeaderOptionalEyebrow[0].${prop}`) ||
  get(data, `articleHeaderRequiredEyebrow[0].${prop}`) ||
  get(data, `articleHeaderTitleOnly[0].${prop}`, '');

export const pickArticleHeaderContent = pickArticleHeader('articleTitle.content');
export const pickArticleHeaderImageURL = pickArticleHeader('imageBackground[0].url');
export const pickArticleHeaderEyebrow = pickArticleHeader('eyebrow');

export const getArticleHeaderVideo = (data, screenBreakpoint) => {
  const breakpoint =
    screenBreakpoint === 'largeDesktop' || screenBreakpoint === 'smallDesktop'
      ? 'desktop'
      : screenBreakpoint;
  const isEmbedVideos = get(data, 'videoType') === 'embedVideos';
  const videoBreakpointOrder = ['desktop', 'tablet', 'mobile'].sort(videoBreakpoint =>
    videoBreakpoint === breakpoint ? -1 : 1
  );
  const videoProp = isEmbedVideos ? 'videoBackground' : 'videoAssetBackground';
  let video;

  videoBreakpointOrder.some(videoBreakpoint => {
    video = get(data, `${videoProp}${upperFirst(videoBreakpoint)}${isEmbedVideos ? '' : '[0]'}`);
    return video;
  });

  return video;
};

export default {
  externalContent: articleHeaderTitleOnly,
  news: articleHeaderNoEyebrow,
  curatedWork: articleHeaderOptionalEyebrow,
  caseStudy: articleHeaderRequiredEyebrow,
  service: articleHeaderOptionalEyebrow,
  thoughtLeadership: articleHeaderOptionalEyebrow,
  press: articleHeaderNoEyebrow,
  event: articleHeaderNoEyebrow,
  account: articleHeaderOptionalEyebrow,
  singlePage: articleHeaderRequiredEyebrow
};
