import { get, head, camelCase } from 'helpers/utilities';

const getPageType = pathname => {
  const route = pathname.substr(1) !== '' ? pathname.substr(1) : 'home';
  const routeSegments = route.split('/');
  let pageType;

  switch (routeSegments[0]) {
    case 'things-we-make':
      if (routeSegments.length > 1) {
        pageType = 'case-study';
        break;
      }
      pageType = head(routeSegments);
      break;
    case 'says-a-lot':
      if (routeSegments.length > 1) {
        pageType = get(routeSegments, '[1]');
        break;
      }
      pageType = head(routeSegments);
      break;
    case 'careers':
      if (routeSegments.length > 1) {
        pageType = 'jobDetail';
        break;
      }
      pageType = head(routeSegments);
      break;
    case 'about-us':
      pageType = head(routeSegments);
      break;
    case '/':
    case 'home':
    case 'why-we-make':
    case 'location':
    case 'new-business':
      pageType = head(routeSegments);
      break;
    case 'contact':
    case 'search':
      pageType = 'home';
      break;
    case 'services':
      pageType = 'service';
      break;
    default:
      pageType = 'info';
  }

  return camelCase(pageType);
};

export default getPageType;
