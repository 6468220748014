import React, { useState, useEffect, useRef, useCallback } from 'react';
import { withRouter, useLocation } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import { CSSTransition } from 'react-transition-group';
import { get } from 'helpers/utilities';
import {
  scrollTo,
  isBodyScrollDisabled,
  disableBodyScroll,
  enableBodyScroll
} from 'helpers/scroll';
import classNames from 'classnames/bind';

import allCards from 'queries/allCards';

// a11y
import maintainTabFocus from 'ally.js/maintain/tab-focus';
import whenKey from 'ally.js/when/key';

import EventHandler from 'helpers/EventHandler';
import canUseDOM from 'helpers/canUseDOM';

import { EVENT } from 'config/constants';

import useTimeout from '../../hooks/useTimeout';
import useNavigation from '../../hooks/useNavigation';
import useGlobalSearchContent from '../../hooks/useGlobalSearchContent';
import useAccesibility from '../../hooks/useAccessibility';

import ArticleSuggestions from './ArticleSuggestions';
import SearchFilters from './SearchFilters';
import SearchSuggestions from './SearchSuggestions';
import styles from './styles.scss';
import SearchCards from './SearchCards';
import SearchResultCount from './SearchResultCount';

const cx = classNames.bind(styles);

const getSearchQuery = (safeSearch, limit, offset) => gql`
    query searchQuery {
        searchEntry(search: "${safeSearch}", limit: ${limit}, offset: ${offset}) {
            searchResults {
                title
                uri
                ${allCards}
            }
            resultsCount
        }
    }
`;

const SearchOverlay = props => {
  const { setOverlay, history, overlay } = props;
  const isSearchOpen = overlay === 'search';

  const dataSearchOverlay = useGlobalSearchContent();

  const { setNavOverlay } = useNavigation();
  const location = useLocation();
  const [currentSearch, setCurrentSearch] = useState('');
  const [scrollDirection, setScrollDirection] = useState('up');
  const [canClose, setCanClose] = useState(false);
  const [hashChecked, setHashChecked] = useState(false);
  const [paginationOffset, setPaginationOffset] = useState(0);
  const [selectedPage, setSelectedPage] = useState(0);
  const [overlayClosed, setOverlayClosed] = useState(false);
  const timeout = useTimeout();
  const searchInputRef = useRef();
  const timeoutRef = useRef(null);
  const activeElementRef = useRef();
  const searchOverlayRef = useRef();

  // a11y
  const hiddenHandleRef = useRef(null);
  const keyHandleRef = useRef(null);
  const tabHandleRef = useRef(null);
  const closeButtonRef = useRef();
  const resultsPerPage = useRef(12);

  const { setCurrentScreenReaderMessage } = useAccesibility();

  const sectionStyles = cx('searchOverlay__section', {
    'searchOverlay__section--scrollUp': scrollDirection === 'down'
  });

  const getSafeSearchValue = useCallback(
    () => currentSearch.replace(/\\/g, '\\\\').replace(/"/g, '\\"'),
    [currentSearch]
  );

  const { loading: isSearchQueryLoading, data: searchResults } = useQuery(
    getSearchQuery(getSafeSearchValue(), resultsPerPage.current, paginationOffset),
    {
      onCompleted: data => {
        if (isSearchOpen) {
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: 'searchInitiation',
            searchTerms: currentSearch,
            numOfSearchResults: get(data, 'searchEntry.resultsCount')
          });
        }
      }
    }
  );

  const searchEntries = get(searchResults, 'searchEntry.searchResults');
  const searchTotalCount = get(searchResults, 'searchEntry.resultsCount');

  useEffect(() => {
    if (canUseDOM && isSearchOpen && !isBodyScrollDisabled()) {
      disableBodyScroll({ overlay: true });
    } else if (canUseDOM && !isSearchOpen && isBodyScrollDisabled()) {
      enableBodyScroll({ overlay: true });
    }
  }, [isSearchOpen]);

  // componentDidMount
  useEffect(() => {
    const scrollListener = EventHandler.subscribe(EVENT.SCROLL, (_, e) => {
      if (!isSearchOpen) {
        return;
      }
      if (e.direction !== scrollDirection) {
        setScrollDirection(e.direction);
      }
    });

    // Cleanup
    return () => {
      setOverlay('');
      EventHandler.unsubscribe(scrollListener);
    };
  }, []);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    if (!hashChecked && urlParams.has('query') && document.querySelector('#search')) {
      const searchQuery = decodeURI(urlParams.get('query'));
      document.querySelector('#search').value = searchQuery;
      if (currentSearch !== searchQuery) {
        setCurrentSearch(searchQuery);
        setHashChecked(true);
      }
    }
  }, [hashChecked, currentSearch]);

  useEffect(() => {
    if (isSearchOpen) {
      _addAlly();
    } else {
      _removeAlly();
    }
  }, [isSearchOpen]);

  useEffect(() => {
    if (overlayClosed) {
      setNavOverlay(false);

      const state = get(window, 'history.state');

      setOverlayClosed(false);

      if (state) history.goBack();
      else history.push('/');
    }
  }, [overlayClosed]);

  useEffect(() => {
    // Scroll back to top before changing cards.
    if (searchOverlayRef.current && searchInputRef.current) {
      scrollTo(
        { top: 0, behavior: 'smooth' },
        () => {
          setCurrentScreenReaderMessage(
            `Displaying page ${selectedPage + 1} of ${searchTotalCount} results`
          );
          searchInputRef.current.focus();
        },
        searchOverlayRef.current
      );
    }
  }, [selectedPage, paginationOffset]);

  const onEntered = useCallback(() => {
    setCanClose(true);
    if (location.pathname !== '/search') history.push('/search');
  }, [location.pathname]);

  const onClickClose = useCallback(() => {
    if (!canClose) return;

    searchInputRef.current.value = '';
    setCanClose(true);
    setCurrentSearch('');
    setOverlayClosed(true);
  }, [canClose]);

  const pressHandler = useCallback(
    e => {
      e.persist();
      clearTimeout(timeoutRef.current);

      timeoutRef.current = timeout(() => {
        // Prevent doing the query again if the user press any other
        // key without changing the search string.
        if (e.target.value !== currentSearch) {
          setCurrentSearch(e.target.value);
          setPaginationOffset(0);
          setSelectedPage(0);
        }
      }, 500);
    },
    [currentSearch]
  );

  const closeSearch = useCallback(() => {
    setOverlay('');
    setNavOverlay(false);

    searchInputRef.current.value = '';
    setCurrentSearch('');
  }, []);

  const _addAlly = useCallback(() => {
    activeElementRef.current = document.activeElement;
    // Focus on close button reference
    closeButtonRef.current.focus();

    timeout(() => {
      /*
       * React to ESC Key
       * Overlay will close when ESC Key is pressed.
       */
      keyHandleRef.current = whenKey({
        escape: () => onClickClose()
      });

      /**
       * Make sure the Tab Key controlled focus is trapped within
       * the tab sequence of the Overlay and doesn't reach
       * the browser's UI.
       */
      tabHandleRef.current = maintainTabFocus({
        context: searchOverlayRef.current
      });
    }, 500);
  }, []);

  const _removeAlly = useCallback(() => {
    if (hiddenHandleRef.current) hiddenHandleRef.current.disengage();
    if (keyHandleRef.current) keyHandleRef.current.disengage();
    if (tabHandleRef.current) tabHandleRef.current.disengage();
    if (activeElementRef.current) activeElementRef.current.focus();
  }, []);

  return (
    <CSSTransition
      in={isSearchOpen}
      timeout={1000}
      classNames={styles.searchOverlay}
      unmountOnExit={true}
      onEntered={() => onEntered()}>
      <section
        role="dialog"
        className={styles.searchOverlay}
        id="searchOverlay"
        ref={searchOverlayRef}
        aria-label="Search Overlay"
        aria-describedby="search-description">
        <div className={sectionStyles}>
          <button
            type="button"
            className={styles.searchOverlay__close}
            onClick={() => onClickClose()}
            aria-label="Close Search Overlay"
            ref={closeButtonRef}>
            <span />
            <span />
          </button>
          <div className={styles.searchOverlay__input}>
            <label className={styles.searchOverlay__label} id="search-description" htmlFor="search">
              What do you want to see?
            </label>
            <input
              type="text"
              id="search"
              placeholder="Search"
              autoComplete="off"
              ref={searchInputRef}
              className={styles.searchOverlay__inputText}
              onKeyUp={pressHandler}
            />
          </div>
          {currentSearch.length > 1 ? (
            <>
              <div className={styles.searchOverlay__searchSuggestions}>
                <SearchSuggestions
                  safeSearch={getSafeSearchValue()}
                  currentSearch={currentSearch}
                  closeSearch={closeSearch}
                />
                <SearchResultCount
                  totalCount={searchTotalCount}
                  isSearchQueryLoading={isSearchQueryLoading}
                />
              </div>
              <SearchCards
                safeSearch={getSafeSearchValue()}
                isSearchOpen={isSearchOpen}
                closeSearch={closeSearch}
                paginationOffset={paginationOffset}
                selectedPage={selectedPage}
                setPaginationOffset={setPaginationOffset}
                setSelectedPage={setSelectedPage}
                searchEntries={searchEntries}
                isSearchQueryLoading={isSearchQueryLoading}
                totalCount={searchTotalCount}
                resultsPerPage={resultsPerPage}
              />
            </>
          ) : (
            <>
              <SearchFilters isSearchOpen={isSearchOpen} closeSearch={closeSearch} />
              <ArticleSuggestions
                dataSearchOverlay={dataSearchOverlay}
                currentSearch={currentSearch}
                closeSearch={closeSearch}
                isSearchOpen={isSearchOpen}
              />
            </>
          )}
        </div>
      </section>
    </CSSTransition>
  );
};

export default withRouter(SearchOverlay);
