import React, { useEffect, useCallback } from 'react';
import { get, find } from 'helpers/utilities';

import Card from 'components/Cards';
import LoadingDots from 'components/LoadingDots';
import Pagination from 'components/Pagination';
import SearchFilters from './SearchFilters';

import useGreenHouseJobs from '../../hooks/useGreenHouseJobs';
import useAccesibility from '../../hooks/useAccessibility';

import styles from './styles.scss';

const parseJobCards = (jobs, safeSearch) =>
  jobs
    .map(job => {
      const list = {
        title: job.title,
        uri: `careers/${job.id}`,
        cardDetailedJob: [
          {
            jobLocation: job.location.name,
            jobTitle: job.title,
            jobDescription: job.content
          }
        ]
      };
      return list;
    })
    .filter(
      job =>
        job.title.toLowerCase().search(safeSearch.toLowerCase()) !== -1 ||
        job.cardDetailedJob[0].jobLocation.toLowerCase().search(safeSearch.toLowerCase()) !== -1
    );

const parseEntryCards = (entries = [], safeSearch) => {
  let entriesModified = null;
  const firstAccount = find(entries, (entry, i) => {
    if (
      get(entry, 'title').toLowerCase().search(safeSearch.toLowerCase()) !== -1 &&
      get(entry, 'cardAccount')
    ) {
      entriesModified = entries.slice();
      entriesModified.splice(i, 1);
      return true;
    }
    return false;
  });

  if (firstAccount) {
    entriesModified.unshift(firstAccount);
  }

  return !entriesModified ? entries : entriesModified;
};

const SearchCards = ({
  safeSearch,
  isSearchOpen,
  closeSearch,
  selectedPage,
  setSelectedPage,
  setPaginationOffset,
  searchEntries,
  totalCount,
  resultsPerPage,
  isSearchQueryLoading
}) => {
  const jobs = useGreenHouseJobs();
  const { setCurrentScreenReaderMessage } = useAccesibility();

  const handlePageClick = useCallback(data => {
    const selectedPage = data.selected;
    const offset = selectedPage * resultsPerPage.current;

    setSelectedPage(selectedPage);
    setPaginationOffset(offset);
  }, []);

  useEffect(() => {
    if (typeof totalCount !== 'undefined' && safeSearch) {
      const timeout = setTimeout(() => {
        if (totalCount <= 0) {
          setCurrentScreenReaderMessage(`Sorry, there are no results matching your search.`);
        } else {
          setCurrentScreenReaderMessage(`Found ${totalCount} search results for ${safeSearch}`);
        }
      }, 500);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [totalCount]);

  if (isSearchQueryLoading) {
    return <LoadingDots className={styles.loadingDots} color="black" />;
  }

  const jobCards = parseJobCards(jobs, safeSearch);
  const entryCards = parseEntryCards(searchEntries, safeSearch);

  const cards = [...entryCards, ...jobCards];
  const pageCount = Math.ceil(totalCount / resultsPerPage.current);

  if (cards.length <= 0) {
    return (
      <>
        <p className={styles.searchOverlay__noResult}>
          Sorry, there are no results matching your search.
        </p>
        <SearchFilters isSearchOpen={isSearchOpen} closeSearch={closeSearch} />
      </>
    );
  }

  return (
    <>
      {cards.length ? (
        <>
          <div className={styles.searchOverlay__cards}>
            {cards.map((entry, i) => (
              <Card
                key={i}
                onClick={closeSearch}
                {...entry}
                size="standard"
                transparentCard="black"
                className={styles.searchOverlay__card}
              />
            ))}
          </div>
          {pageCount > 1 && (
            <Pagination
              forcePage={selectedPage}
              pageCount={pageCount}
              onPageClick={handlePageClick}
            />
          )}
        </>
      ) : null}
    </>
  );
};

export default SearchCards;
