import React, { useState, useEffect } from 'react';

const ReducedMotion = React.createContext({ isReducedMotionActive: false });

export const ReducedMotionProvider = ({ children }) => {
  const [isReducedMotionActive, setIsReducedMotionActive] = useState(false);

  // componentDidMount
  useEffect(() => {
    const mediaQuery = window.matchMedia('(prefers-reduced-motion: reduce)');

    setIsReducedMotionActive(mediaQuery.matches);

    const listener = () => {
      setIsReducedMotionActive(mediaQuery.matches);
    };

    mediaQuery.addListener(listener);
    return mediaQuery.removeListener(listener);
  }, []);

  return (
    <ReducedMotion.Provider value={{ isReducedMotionActive }}>{children}</ReducedMotion.Provider>
  );
};

export default ReducedMotion;
