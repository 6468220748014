import { get } from 'helpers/utilities';
import articleHeaders from './articleHeader';
import cardQueries from './cardQueries';

const components = {
  carouselHero: `
        carouselHero {
            ... on CarouselHeroCarouselHeroItem {
                videoType
                clientLabel {
                    content
                }
                background {
                    url
                    title
                    mimeType
                    extension
                    ... on ImagesVolume {
                      assetAltText
                    }
                }
                videoBackground {
                    url
                }
                videoAssetBackground {
                    url
                    title
                }
                project {
                    url
                    uri
                    title
                    ... on CaseStudiesCaseStudy {
                        ${articleHeaders.caseStudy}
                    }
                    ... on ThoughtLeadership {
                        ${articleHeaders.thoughtLeadership}
                    }
                    ... on News {
                        ${articleHeaders.news}
                    }
                }
            }
        }
    `,
  featuresModule: {
    name: `
            featuresModule {
                __typename
            }
        `,
    component: query => `
            featuresModule {
                ${query}
            }
        `
  },
  filters: {
    name: `
            filters {
                ... on FiltersFilterTags {
                    id
                }
            }
        `,
    component: `
            filters {
                ... on FiltersFilterTags {
                    tags {
                        tagPageEntry {
                            uri
                            title
                            ... on PromotedTag {
                                associatedType
                                taxonomy {
                                    title
                                    tagTitle
                                }
                                outputCategory {
                                    title
                                    tagTitle
                                }
                            }
                        }
                        tagBackground {
                            url
                            title
                            extension
                        }
                        tagVideoBackground {
                            url
                        }
                    }
                }
            }
        `
  },
  allWork: {
    name: `
            allWorkCardGrid {
                __typename
            }
        `,
    component: `
            allWorkCardGrid {
                ... on AllWorkCardGridEntriesCardGrid {
                    entriesYearGrid {
                        hideYear
                        startYear
                        endYear
                        rangeType
                        gridLayout
                        title
                        collectionEntries {
                            uri
                            title
                            ${cardQueries.article}
                            ${cardQueries.project}
                            ${cardQueries.curatedWork}
                        }
                    }
                    awardsModule {
                        ... on FeaturesAwards {
                            awards {
                                ... on AwardsAwardsItem {
                                    caption {
                                        content
                                    }
                                    awardCards {
                                        awardLink {
                                            uri
                                            title
                                        }
                                        awardBrand
                                        awardName
                                        awardCategory
                                        awardLogo {
                                            url
                                            title
                                        }
                                        awardBackground {
                                            url
                                            title
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        `
  },
  articleBodyFeaturesModule: {
    name: `
            articleBody {
                __typename
            }
        `,
    component: query => `
            articleBody {
                ... on ArticleBodyFeaturedEntries {
                    featuresModule {
                        ${query}
                    }
                }
            }
        `
  }
};

const features = {
  awards: `
        ... on FeaturesAwards {
            awards {
                __typename
                ... on AwardsAwardsItem {
                    caption {
                        content
                    }
                    awardCards {
                        awardLink {
                            uri
                            title
                        }
                        awardBrand
                        awardName
                        awardCategory
                        awardLogo {
                            url
                            title
                        }
                        awardBackground {
                            url
                            title
                        }
                    }
                }
            }
        }
    `,
  brandSpotlight: `
        ... on FeaturesBrandSpotlight {
            brandSpotlight {
                __typename
                ... on BrandSpotlightBrandSpotlightItem {
                    contentFields {
                        caption {
                            content
                        }
                        description
                        background {
                            url
                            title
                            extension
                        }
                    }
                    brands {
                        logo {
                            url
                            title
                            extension
                        }
                        brandName
                        associatedPage {
                            uri
                            title
                        }
                    }
                }
            }
        }
    `,
  editorialProject: `
        ... on FeaturesEditorialProject3Up {
            editorialProject {
                __typename
                ... on EditorialProjectEditorialProjectItem {
                    caption {
                        content
                    }
                    description
                    cta {
                        uri
                        title
                    }
                    cards {
                        ... on CardsSuperTableBlockType {
                            card {
                                id
                                title
                                uri
                                postDate
                                ${cardQueries.account}
                                ${cardQueries.project}
                                ${cardQueries.article}
                            }
                        }
                    }
                }
            }
        }
    `,
  events: `
        ... on FeaturesEvents {
            eventsModule {
                __typename
                eventsLinks {
                    ... on EventsLinksInternalLink {
                        internalUrl {
                            title
                            uri
                            ... on Event {
                                leftRailIntroDetails {
                                    ... on LeftRailIntroDetailsEventDetails {
                                        whatLabel
                                        whereLabel
                                        startDateAndTime
                                    }
                                }
                            }
                        }
                    }
                    ... on EventsLinksExternalLink {
                        eventDate
                        eventTitle
                        eventName
                        eventLocation
                        externalUrl
                    }
                }
            }
        }
    `,
  halfUp: `
        ... on FeaturesHalfUp {
            halfUpFeatures {
                __typename
                ... on HalfUpFeaturesHalfUpFeaturesItem {
                    projectData {
                        projectEntry {
                            id
                            uri
                            ... on CaseStudiesCaseStudy {
                                ${articleHeaders.caseStudy}
                            }
                            ... on SinglePage {
                                ${articleHeaders.singlePage}
                            }
                            ${cardQueries.project}
                            ${cardQueries.singlePage}
                        }
                        projectDescription
                        projectTitleSelection {
                            ... on ProjectTitleSelectionUseProjectEntryTitle {
                                id
                            }
                            ... on ProjectTitleSelectionManuallyInputTitle {
                                customTitle {
                                    content
                                }
                            }
                        }
                    }

                    articleData {
                        articleEntry {
                            id
                            uri
                            ... on ThoughtLeadership {
                                ${articleHeaders.thoughtLeadership}
                            }
                            ... on CuratedWorkCuration {
                                ${articleHeaders.curatedWork}
                            }
                            ... on AccountsAccount {
                                ${articleHeaders.account}
                            }
                            ${cardQueries.article}
                            ${cardQueries.curatedWork}
                            ${cardQueries.account}
                        }
                        articleDescription
                        articleTitleSelection {
                            ... on ArticleTitleSelectionUseArticleEntryTitle {
                                id
                            }
                            ... on ArticleTitleSelectionManuallyInputTitle {
                                customTitle {
                                    content
                                }
                            }
                        }
                        articleAsset {
                            url
                            title
                        }
                    }
                }
            }
        }
    `,
  linearProject: `
        ... on FeaturesLinearProjectCollection {
            linearProject {
                __typename
                ... on LinearProjectLinearProjectItem {
                    caption {
                        content
                    }
                    description
                    cards {
                        ... on CardsSuperTableBlockType {
                            card {
                                id
                                title
                                uri
                                postDate
                                ${cardQueries.project}
                                ${cardQueries.event}
                                ${cardQueries.press}
                                ${cardQueries.news}
                                ${cardQueries.singlePage}
                            }
                        }
                    }
                    readMoreCta {
                        uri
                        title
                    }
                }
            }
        }
    `,
  scrollJackProject: `
        ... on FeaturesScrollJackProjectTour {
            scrollJackProject {
                ... on ScrollJackProjectScrollJackProjectItem {
                    featuredArticle {
                        titleSelection {
                            __typename
                            ... on TitleSelectionSelectEntryTitle {
                                selected {
                                    uri
                                    title
                                    ... on AccountsAccount {
                                        ${articleHeaders.account}
                                    }
                                    ... on CuratedWorkCuration {
                                        ${articleHeaders.curatedWork}
                                    }
                                    ... on ThoughtLeadership {
                                        ${articleHeaders.thoughtLeadership}
                                    }
                                }
                            }
                            ... on TitleSelectionCustomEntryTitle {
                                customTitle {
                                    content
                                }
                            }
                        }
                    }
                    description
                    cards {
                        ... on CardsSuperTableBlockType {
                            card {
                                id
                                uri
                                title
                                postDate
                                ${cardQueries.project}
                                ${cardQueries.singlePage}
                            }
                        }
                    }
                }
            }
        }
    `,
  singleBlowout: `
        ... on FeaturesSingleBlowout {
            singleBlowout {
                ... on SingleBlowoutSingleBlowoutItem {
                    backgroundAsset {
                        url
                        title
                    }
                    largeProjectAsset {
                        url
                        title
                    }
                    clientName
                    caseStudyTitle {
                        content
                    }
                    description
                    caseStudyLink {
                        uri
                        title
                    }
                    bulletedList {
                        ... on BulletedListSuperTableBlockType {
                            itemText
                        }
                    }
                    metric {
                        ... on MetricSuperTableBlockType {
                            metricTitle
                            metricNumber
                        }
                    }
                }
            }
        }
    `,
  sizzleVideo: `
        ... on FeaturesSizzleVideoCollection {
            sizzleVideo {
                __typename
                ... on SizzleVideoSizzleVideoItem {
                    videoType
                    caption {
                        content
                    }
                    description
                    cta {
                        uri
                        title
                    }
                    video {
                        url
                        title
                    }
                    videoAsset {
                        url
                        title
                    }
                    sizzleCards {
                        entryCard1 {
                            id
                            uri
                            title
                            ${cardQueries.project}
                        }
                        entryCard2 {
                            id
                            uri
                            title
                            ${cardQueries.project}
                        }
                    }
                }
            }
        }
    `,
  fullWidthVideo: `
        ... on FeaturesFullWidthVideo {
            fullWidthVideo {
                desktopTabletVideo {
                    url
                    title
                }
                desktopTabletPoster {
                    url
                    title
                }
                mobileVideo {
                    url
                    title
                }
                mobilePoster {
                    url
                    title
                }
            }
        }
    `
};

export const getAssociatedTaxonomy = entry => {
  const associatedType = get(entry, 'associatedType');
  if (associatedType === 'industry') {
    return get(entry, 'taxonomy[0]');
  }
  if (associatedType === 'output') {
    return get(entry, 'outputCategory[0]');
  }
  return null;
};

export const getAssociatedTaxonomyConnection = entry => {
  const associatedType = get(entry, 'associatedType');
  if (associatedType === 'industry') {
    return get(entry, 'taxonomyConnection');
  }
  if (associatedType === 'output') {
    return get(entry, 'outputCategoryConnection');
  }
  return null;
};

export { components, features };
