import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { get } from 'helpers/utilities';
import useTimeout from '../../hooks/useTimeout';

const CardLink = (
  {
    onClick,
    to,
    className,
    title,
    isExternalLink = false,
    onTransitionEndCapture,
    style,
    onMouseOver,
    onFocus,
    children
  },
  ref
) => {
  const timeout = useTimeout();
  const location = useLocation();
  if (isExternalLink) {
    return (
      <a
        ref={ref}
        href={get(to, 'pathname')}
        title={title}
        className={className}
        style={style}
        onMouseOver={onMouseOver}
        onFocus={onFocus}
        target="_blank"
        onTransitionEndCapture={onTransitionEndCapture}
        rel="noreferrer">
        {children}
      </a>
    );
  }
  return (
    <Link
      ref={ref}
      to={to}
      onTransitionEndCapture={onTransitionEndCapture}
      onClick={e => {
        const pathname = typeof to === 'string' ? to : to.pathname;
        if (location.pathname === pathname) {
          timeout(() => {
            window.scrollTo({
              top: 0,
              left: 0,
              behavior: 'smooth'
            });
          }, 100);
        }
        if (onClick) onClick(e);
      }}
      onMouseOver={onMouseOver}
      onFocus={onFocus}
      className={className}
      style={style}
      title={title}>
      {children}
    </Link>
  );
};

export default React.forwardRef(CardLink);
