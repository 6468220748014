import React from 'react';
import { hydrate } from 'react-dom';
import { loadableReady } from '@loadable/component';
import { ApolloProvider } from '@apollo/react-common';
import * as Sentry from '@sentry/browser';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import App from './App';

import configureApollo from './helpers/configureApollo';
import { GreenhouseJobsClientProvider } from './contexts/GreenhouseJobs';

const client = configureApollo();

const { env = {} } = window;

if (
  env.RAZZLE_ENVIRONMENT === 'qa' ||
  env.RAZZLE_ENVIRONMENT === 'stage' ||
  env.RAZZLE_ENVIRONMENT === 'production'
) {
  Sentry.init({
    dsn: 'https://0c9a0ac1b37e485b9281836234697cf1@sentry.io/2512572',
    environment: env.RAZZLE_ENVIRONMENT
  });
}

const Client = () => (
  <HelmetProvider>
    <ApolloProvider client={client}>
      <BrowserRouter>
        <GreenhouseJobsClientProvider>
          <App />
        </GreenhouseJobsClientProvider>
      </BrowserRouter>
    </ApolloProvider>
  </HelmetProvider>
);

loadableReady(() => hydrate(<Client />, document.getElementById('main-content')));

if (module.hot) {
  module.hot.accept('./App', () => {
    hydrate(<Client />, document.getElementById('main-content'));
  });
}
