import React, { createContext, useState } from 'react';

const GreenhouseJobs = createContext([]);

export const GreenhouseJobsClientProvider = ({ children }) => {
  const [jobs] = useState(window.__GREENHOUSE_JOBS_STATE__);

  return <GreenhouseJobs.Provider value={jobs}>{children}</GreenhouseJobs.Provider>;
};

export const GreenhouseJobsServerProvider = ({ children, value = [] }) => (
  <GreenhouseJobs.Provider value={value}>{children}</GreenhouseJobs.Provider>
);

export default GreenhouseJobs;
