import React, { useEffect, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import { Link } from 'react-router-dom';

// helpers
import { get } from 'helpers/utilities';
import { capitalize } from 'helpers/text';
import { disableBodyScroll, enableBodyScroll } from 'helpers/scroll';
import { socialClick } from 'helpers/dataLayer';
import deviceInfo from 'helpers/deviceInfo';

// components
import Video from 'components/Video';
import Icon from 'components/Icon';

import classNames from 'classnames';
import useNavigation from '../../hooks/useNavigation';
import useHomeContent from '../../hooks/useHomeContent';
import useGlobalContent from '../../hooks/useGlobalContent';

import styles from './styles.scss';

const getVideo = data =>
  get(data, 'videoType') === 'embedVideos'
    ? get(data, 'navigationVideo')
    : get(data, 'navigationVideoAsset[0]');

const SocialLinks = ({ links, className }) => (
  <ul className={className} aria-label="Social media links">
    {links.map((social, index) => (
      <li key={index}>
        <a
          href={social.socialLinkUrl}
          key={index}
          className={styles.megamenu__socials__link}
          title={`Follow us on ${capitalize(get(social, 'socialLinkType'))}`}
          target="_blank"
          rel="noopener noreferrer"
          onClick={socialClick(get(social, 'socialLinkType'), 'header social click')}>
          <Icon color="white" name={get(social, 'socialLinkType')} />
        </a>
      </li>
    ))}
  </ul>
);

const SubSiteLinks = ({ links, listClassName, linkClassName }) => (
  <ul className={listClassName} aria-label="Code & Theory Sub Site links">
    {links.map(({ secondaryLinkCta, secondaryLink }, index) => (
      <li key={`subsite-link-${index}`}>
        <a
          href={secondaryLink}
          target="_blank"
          rel="noopener noreferrer"
          dangerouslySetInnerHTML={{ __html: get(secondaryLinkCta, 'content', '') }}
          className={linkClassName}
        />
      </li>
    ))}
  </ul>
);

const NavItem = ({ title, navigationHoverTitle, ...rest }) => {
  const isMobile = deviceInfo.isTouch && (deviceInfo.isIOS || deviceInfo.isAndroid);
  const [isHover, setIsHover] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [isShowHoverTitle, setIsShowHoverTitle] = useState(false);

  useEffect(() => {
    setTimeout(() => setIsShowHoverTitle(isActive), 50);
  }, [isActive]);

  return (
    <Link
      onMouseOver={() => {
        if (isMobile) return;
        setIsHover(true);
        setIsActive(true);
      }}
      onMouseOut={() => !isMobile && setIsHover(false)}
      onTransitionEnd={() => !isHover && !isMobile && setIsActive(false)}
      className={classNames(styles.megamenu__primary__link, {
        [styles['megamenu__primary__link--isActive']]: isShowHoverTitle
      })}
      {...rest}>
      <p>
        <span>{title}</span>
        {isActive && <span>{navigationHoverTitle}</span>}
      </p>
    </Link>
  );
};

const OverlayNav = () => {
  const homeContent = useHomeContent();
  const globalContent = useGlobalContent();
  const {
    state: { overlay },
    setNavOverlay
  } = useNavigation();

  useEffect(() => {
    if (overlay) disableBodyScroll({ overlay: true });
    else enableBodyScroll({ overlay: true });
  }, [overlay]);

  const data = get(homeContent, 'homeNavigation');
  const defaultVideo = get(homeContent, 'homeNavigationDefaultVideo[0]');
  const video = getVideo(get(defaultVideo, '[0]'));
  const globalContactOverlayData = get(globalContent, 'globalContactOverlay');

  if (!globalContactOverlayData || !data) return null;

  const socials = get(globalContactOverlayData, 'socialLinks').filter(
    social => social.socialLinkType !== 'facebook'
  );

  const primaryLinks = data.filter(
    navigationItem => get(navigationItem, '__typename') === 'HomeNavigationNavigationItem'
  );

  const secondaryLinks = get(globalContactOverlayData, 'secondaryLinks', []);

  return (
    <CSSTransition in={overlay} timeout={300} classNames={styles.megamenu} unmountOnExit={true}>
      <section className={styles.megamenu}>
        <ul className={styles.megamenu__primary} aria-label="Primary links">
          {primaryLinks.map(({ navigationLink, navigationHoverTitle }, index) => (
            <li key={`primaryLinks${index}`}>
              <NavItem
                key={index}
                to={{ pathname: `/${get(navigationLink, '[0].uri')}` }}
                onClick={() => setNavOverlay(!overlay)}
                title={get(navigationLink, '[0].title')}
                navigationHoverTitle={navigationHoverTitle}
              />
            </li>
          ))}
        </ul>

        <section className={styles.megamenu__secondary}>
          {secondaryLinks.length ? (
            <SubSiteLinks
              links={secondaryLinks}
              listClassName={`${styles.megamenu__secondary__list} ${styles['megamenu__secondary__list--subsite']}`}
              linkClassName={styles.megamenu__secondary__link}
            />
          ) : null}

          <ul className={styles.megamenu__secondary__list} aria-label="Secondary links">
            <li>
              <Link
                to={{ pathname: '/careers' }}
                className={styles.megamenu__secondary__link}
                onClick={() => setNavOverlay(!overlay)}>
                Careers
              </Link>
            </li>
            <li>
              <Link
                to={{ pathname: '/search' }}
                className={styles.megamenu__secondary__link}
                onClick={() => setNavOverlay(!overlay)}>
                Search
              </Link>
            </li>
          </ul>

          {socials.length ? (
            <SocialLinks className={styles.megamenu__socials} links={socials} />
          ) : null}
        </section>
        {video ? (
          <Video
            src={video.url}
            loop={true}
            muted={true}
            autoplay={true}
            togglePlay={true}
            className={styles.megamenu__video}
          />
        ) : null}
      </section>
    </CSSTransition>
  );
};

export default OverlayNav;
