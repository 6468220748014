import React from 'react';
import { Link } from 'react-router-dom';
import Meta from 'components/Meta';
import styles from './styles.scss';

const ServerError = ({ error }) => (
  <>
    <Meta active={true} />
    <section className={styles['error-container']}>
      <video className={styles['error-video']} playsInline autoPlay muted loop>
        <source src="/videos/500.mp4" type="video/mp4" />
        <source src="/videos/500.webm" type="video/webm" />
      </video>

      <div className={`${styles['error-details__inner']}`}>
        <aside className={styles['error-details']}>
          <p>
            <strong>Internal Server Error</strong>
          </p>
          <Link to="/" title="Back to Homepage">
            Back to Homepage
          </Link>
        </aside>
      </div>
      <code style={{ display: 'none', color: '#ffffff' }}>{error}</code>
    </section>
  </>
);

export default ServerError;
