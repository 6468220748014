import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { createHttpLink } from 'apollo-link-http';

import { getPreviewVars } from './preview';
import { apiPath, apiToken } from '../config/constants';
import introspectionQueryResultData from '../../../fragmentTypes.json';

// Create a new fragment matcher
const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData
});

const configureApollo = () => {
  const client = new ApolloClient({
    link: createHttpLink({
      uri: apiPath + getPreviewVars(),
      credentials: 'same-origin',
      headers: {
        authorization: apiToken ? `bearer ${apiToken}` : ''
      }
    }),
    cache: new InMemoryCache({ fragmentMatcher }).restore(window.__APOLLO_STATE__),
    ssrForceFetchDelay: 100
  });

  return client;
};

export default configureApollo;
