/**
 * Triggers dataLayer update on a social link click
 * @param  {String} socialNetwork  -   linkedin | facebook | twitter | instagram
 * @param  {String} socialType - 'social share' | 'highlight share' | 'footer social click' | 'header social click'
 */
export const socialShare = (socialNetwork, socialType = 'social share') => {
  window.dataLayer.push({
    event: 'social',
    socialType,
    socialNetwork
  });
};

/**
 * Composes a click handler for a social link click
 * @param  {[type]} socialNetwork [description]
 * @param  {String} socialType    [description]
 * @return {[type]}               [description]
 */
export const socialClick =
  (socialNetwork, socialType = 'social share') =>
  () =>
    socialShare(socialNetwork, socialType);
