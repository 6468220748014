import { useRef, useEffect } from 'react';

const useTimeout = () => {
  const timeouts = useRef([]);

  useEffect(
    () => () => {
      timeouts.current.forEach(id => window.clearTimeout(id));
      timeouts.current = [];
    },
    []
  );

  return (cb, delay) => {
    const id = window.setTimeout(cb, delay);
    timeouts.current.push(id);
    return id;
  };
};

export default useTimeout;
