import { gql } from 'apollo-boost';
import metaDetails from './metaDetails';
import contactModule from './contactModule';
import { components } from './twm_components';

const { carouselHero, featuresModule, filters, allWork } = components;

const theThingsWeMake = {
  main: `
        ... on TheThingsWeMake {
            ${carouselHero}
            ${featuresModule.name}
            ${filters.name}
            ${allWork.name}
            ${contactModule}
            ${metaDetails}
        }
    `,
  component: query => gql`
        query filtersQuery {
            entry(slug: "things-we-make") {
                ... on TheThingsWeMake {
                    ${query}
                }
            }
        }
    `
};

export default theThingsWeMake;
