import React, { createContext, useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import EventHandler from 'helpers/EventHandler';
import { get, includes } from 'helpers/utilities';
import { EVENT } from 'config/constants';
import useTimeout from '../hooks/useTimeout';

import styles from './Accessibility.scss';

const Accessibility = createContext({
  isClientPassingThroughLinks: false,
  resetAccessibility: () => {},
  autoplayVideos: true,
  setCurrentScreenReaderMessage: () => {}
});

export const AccessibilityProvider = ({ children }) => {
  const [isClientPassingThroughLinks, setIsClientPassingThroughLinks] = useState(false);
  const [autoplayVideos, setAutoplayVideos] = useState(true);
  const [currentAllyMessage, setCurrentScreenReaderMessage] = useState(null);
  const timeout = useTimeout();
  const location = useLocation();
  const focuserRef = useRef();

  const clickRefocus = useCallback((e, cb) => {
    const elem = e.currentTarget;
    elem.blur();
    cb();
    timeout(() => {
      elem.focus();
    }, 100);
  }, []);

  const skipLink = useCallback(() => {
    const firstPage = document.querySelector('.page');

    // Make the page itself focusable.
    firstPage.setAttribute('tabindex', 0);

    // Focus on page
    firstPage.focus();
  }, []);

  useEffect(() => {
    const eventKeydown = EventHandler.subscribe(EVENT.KEYDOWN, (_, event) => {
      const keycode = event.keyCode;
      if (
        keycode === 9 ||
        (event.ctrlKey && event.altKey && keycode === 37) ||
        (event.ctrlKey && event.altKey && keycode === 39)
      ) {
        setIsClientPassingThroughLinks(true);
      }
    });

    return () => EventHandler.unsubscribe(eventKeydown);
  }, []);

  useEffect(() => {
    // Remove the A11Y status element after being announced by reader.
    if (currentAllyMessage !== null && currentAllyMessage.length) {
      timeout(() => {
        setCurrentScreenReaderMessage(null);
      }, 2000);
    }
  }, [location, currentAllyMessage]);

  const noSkip = includes(
    ['', 'home', 'contact', 'search', 'new-business'],
    get(location, 'pathname', '').split('/')[1]
  );

  const value = useMemo(
    () => ({
      isClientPassingThroughLinks,
      resetAccessibility: () =>
        isClientPassingThroughLinks && setIsClientPassingThroughLinks(false),
      autoplayVideos,
      setCurrentScreenReaderMessage
    }),
    [autoplayVideos, isClientPassingThroughLinks]
  );

  return (
    <Accessibility.Provider value={value}>
      {currentAllyMessage ? (
        <div className={styles['sr-only']} role="status" aria-live="polite">
          <span>{currentAllyMessage}</span>
        </div>
      ) : null}
      <div
        aria-label="Accessibility buttons"
        ref={focuserRef}
        tabIndex={-1}
        id="accessibility-buttons">
        {!noSkip && (
          <button
            type="button"
            aria-label="Skip to the main content"
            title="Skip To The Main Content"
            className={styles.a11y_button}
            onClick={() => skipLink()}>
            <span>Skip to the main content</span>
          </button>
        )}
        <button
          type="button"
          aria-label={`Turn ${
            autoplayVideos || typeof autoplayVideos === 'undefined' ? 'off' : 'on'
          } autoplay on videos`}
          title={`Turn ${
            autoplayVideos || typeof autoplayVideos === 'undefined' ? 'off' : 'on'
          } autoplay on videos`}
          className={styles.a11y_button}
          onClick={e =>
            clickRefocus(e, () =>
              setAutoplayVideos(!(typeof autoplayVideos === 'undefined' || autoplayVideos))
            )
          }>
          <span>
            Turn {autoplayVideos || typeof autoplayVideos === 'undefined' ? 'off' : 'on'} autoplay
            on videos
          </span>
        </button>
      </div>
      {children}
    </Accessibility.Provider>
  );
};

export default Accessibility;
