import { includes } from 'helpers/utilities';
import { compose, split, tail } from './fp';

/**
 * @NOTE: All media helpers should be placed here.
 */

export const checkMediaType = (type = '') => {
  const imageExtensions = ['jpeg', 'jpg', 'png', 'gif'];
  const videoExtensions = ['mp4', 'webm'];
  let mediaType;

  if (includes(imageExtensions, type.toLowerCase())) {
    mediaType = 'image';
  } else if (includes(videoExtensions, type.toLowerCase())) {
    mediaType = 'video';
  } else {
    mediaType = '';
  }

  return mediaType;
};

export const getFileExtension = compose(tail, split('.'));

export const getFileNameFromUrl = compose(tail, split('/'));

export const getFileExtensionFromUrl = compose(getFileExtension, getFileNameFromUrl);

export default null;
