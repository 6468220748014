import React from 'react';
import { get } from 'helpers/utilities';
import Image from 'components/Image';
import Newsletter from 'components/Newsletter';

import styles from './styles.scss';

const NewsletterCard = ({ newsletterSignup, id }) => (
  <div className={styles.newsletterCard}>
    <div className={styles.newsletterCard__image}>
      <Image
        src={get(newsletterSignup[0], 'newsletterSignupLogo[0].url')}
        alt=""
        sizes={{ largeDesktop: 340 }}
      />
    </div>
    <p>{get(newsletterSignup, '[0]newsletterSignupDescription')}</p>
    <div className={styles.newsletterCard__form}>
      <Newsletter isMinified={true} {...newsletterSignup[0]} id={id || 'newsletter_card'} />
    </div>
  </div>
);

export default NewsletterCard;
