import React, { useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import { Link, useLocation } from 'react-router-dom';
import classNames from 'classnames/bind';

// helpers
import { get, includes } from 'helpers/utilities';
import { split, compose, take } from 'helpers/fp';
import { capitalize } from 'helpers/text';
import { disableBodyScroll, enableBodyScroll } from 'helpers/scroll';
import { socialClick } from 'helpers/dataLayer';

// components
import Logo from 'components/Logo';
import Icon from 'components/Icon';
import HamburgerMenu from 'components/HamburgerMenu';

import deviceInfo from 'helpers/deviceInfo';
import useNavigation from '../../hooks/useNavigation';
import useHomeContent from '../../hooks/useHomeContent';
import useGlobalContent from '../../hooks/useGlobalContent';
import useOverlay from '../../hooks/useOverlay';
import usePrevious from '../../hooks/usePrevious';
import useBreakpoint from '../../hooks/useBreakpoint';

import styles from './styles.scss';

const cx = classNames.bind(styles);

const getColor = compose(take(0), split('-'));

const hamburgerClick = (() => {
  let navThemeAfterClick = null;
  return (overlay, theme, setNavOverlay, setNavTheme) => {
    setNavOverlay(!overlay);
    if (overlay) {
      setNavTheme(navThemeAfterClick);
    } else {
      navThemeAfterClick = theme;
      setNavTheme('white');
    }
  };
})();

const SocialLinks = ({ links }) => {
  const {
    state: { activeLink }
  } = useNavigation();
  const isHome = includes(['', '/'], activeLink);

  return (
    <ul className={`${styles.navigation__secondary__list}`} aria-label="Social media links">
      {links.map((social, index) => (
        <li key={`sociallinks${index}`}>
          <a
            href={social.socialLinkUrl}
            key={index}
            className={`${styles['navigation__secondary__link--social']}${
              isHome ? '__home' : '__others'
            }`}
            title={`Follow us on ${capitalize(get(social, 'socialLinkType'))}`}
            target="_blank"
            rel="noopener noreferrer"
            onClick={socialClick(get(social, 'socialLinkType'), 'header social click')}>
            <Icon color="white" name={get(social, 'socialLinkType')} />
          </a>
        </li>
      ))}
    </ul>
  );
};

const SubSiteLinks = ({ links }) => {
  const {
    state: { activeLink }
  } = useNavigation();
  const isHome = includes(['', '/'], activeLink);

  return (
    <ul
      className={`${styles.navigation__secondary__list} ${styles['navigation__secondary__list--subsite']}`}
      aria-label="Code & Theory Sub Site links">
      {links.map(({ secondaryLinkCta, secondaryLink }, index) => (
        <li key={`subsite-link-${index}`}>
          <a
            href={secondaryLink}
            target="_blank"
            rel="noopener noreferrer"
            dangerouslySetInnerHTML={{
              __html: get(secondaryLinkCta, 'content', '')
            }}
            className={`${styles.navigation__secondary__link}
            ${
              `${window.location.protocol}//${window.location.host}/${activeLink.slice(1)}` ===
                secondaryLink && styles['navigation__secondary__link--active']
            }
            ${styles['navigation__secondary__link--subsite']}${isHome ? '__home' : '__others'}`}
          />
        </li>
      ))}
    </ul>
  );
};

const MainNavigation = () => {
  const homeContent = useHomeContent();
  const globalContent = useGlobalContent();
  const location = useLocation();
  const previousLocation = usePrevious(location.pathname);
  const breakpoint = useBreakpoint();
  const { setOverlay, overlayIds, triggeredId, setTriggeredId } = useOverlay();
  const {
    state: { overlay, theme, activeLink, visible, gradient, showPrimaryLinks, showLogo },
    setNavOverlay,
    setNavTheme
  } = useNavigation();
  const pathname = get(location, 'pathname', '').split('/')[1];

  const contactBtnClick = () => {
    setTriggeredId(overlayIds.current.contactIP);
    setOverlay('contact');
  };

  const searchBtnClick = () => {
    setTriggeredId('nav-search-btn');
    setOverlay('search');
  };

  const containerClass = cx('header', `header--${getColor(theme)}`, {
    'header--hidden': !visible,
    'header--isGradient': gradient,
    'header--isSafari': deviceInfo.isSafari && !deviceInfo.isIOS
  });

  useEffect(() => {
    if (overlay) disableBodyScroll({ overlay: true });
    else enableBodyScroll({ overlay: true });
  }, [overlay]);

  useEffect(() => {
    if (triggeredId === null) return;
    if (previousLocation === '/search' || previousLocation === '/contact') {
      setTriggeredId(null);
    }
  }, [location.pathname]);

  if (!homeContent || !globalContent || includes(['contact', 'search', 'new-business'], pathname))
    return null;

  const data = get(homeContent, 'homeNavigation');
  const globalContactOverlayData = get(globalContent, 'globalContactOverlay');

  if (!globalContactOverlayData || !data) return null;

  let showSocials = true;
  let showSubSites = true;
  let showHamburger = true;
  const isHome = includes(['', '/'], activeLink);

  const socials = get(globalContactOverlayData, 'socialLinks').filter(
    social => social.socialLinkType !== 'facebook'
  );
  const primaryLinks = data.filter(
    navigationItem => get(navigationItem, '__typename') === 'HomeNavigationNavigationItem'
  );
  const subSiteLinks = get(globalContactOverlayData, 'secondaryLinks', []);
  let secondaryLinks = [
    {
      name: 'careers',
      link: (
        <Link
          to={{ pathname: '/careers' }}
          className={`${styles.navigation__secondary__link} ${
            activeLink.slice(1) === 'careers' && styles['navigation__secondary__link--active']
          }`}>
          Careers
        </Link>
      )
    },
    {
      name: 'contact',
      link: (
        <button
          id={overlayIds.current.contactIP}
          type="button"
          onClick={() => contactBtnClick()}
          className={styles.navigation__secondary__link}>
          Contact
        </button>
      )
    },
    {
      name: 'search',
      link: (
        <button
          id="nav-search-btn"
          type="button"
          onClick={() => searchBtnClick()}
          className={styles.navigation__secondary__link}>
          Search
        </button>
      )
    }
  ];

  // no socials on non-home pathname whenn mobile
  if (!isHome && (breakpoint === 'tablet' || breakpoint === 'mobile')) {
    showSocials = false;
    secondaryLinks = secondaryLinks.filter(({ name }) => name === 'contact');
  }

  if (!isHome && breakpoint === 'mobile') {
    showSubSites = false;
  }

  if (isHome && breakpoint === 'tablet') {
    showHamburger = false;
  }

  // should put on css? or here for consistency inline with showSocials
  if (isHome && breakpoint === 'mobile') {
    showSubSites = false;
    showSocials = false;
  }

  const navigationClass = cx('navigation', `navigation--${getColor(theme)}`);

  return (
    <CSSTransition in={visible} timeout={250} classNames={styles.header}>
      <nav className={containerClass} aria-label="Main Navigation">
        {showLogo && (
          <Link
            to="/"
            className={`${styles.logo}${isHome ? '__home' : '__others'}`}
            title="Code &amp; Theory">
            <Logo fill={getColor(theme)} />
          </Link>
        )}
        <section className={navigationClass}>
          <section className={styles.navigation__secondary}>
            {showSocials && socials.length ? <SocialLinks links={socials} /> : null}

            {showSubSites && subSiteLinks.length ? <SubSiteLinks links={subSiteLinks} /> : null}

            <ul className={styles.navigation__secondary__list} aria-label="Secondary links">
              {secondaryLinks.map(({ link }, index) => (
                <li key={`secondary${index}`}>{link}</li>
              ))}
            </ul>
          </section>
          {showPrimaryLinks && (
            <ul className={styles.navigation__primary} aria-label="Primary links">
              {primaryLinks.map(({ navigationLink }, index) => (
                <li key={`primary${index}`}>
                  <Link
                    key={index}
                    to={{ pathname: `/${get(navigationLink, '[0].uri')}` }}
                    className={`${styles.navigation__primary__link}${
                      isHome ? '__home' : '__others'
                    } ${
                      get(navigationLink, '[0].uri') === activeLink.slice(1) &&
                      styles['navigation__primary__link--active']
                    }`}>
                    {get(navigationLink, '[0].title')}
                  </Link>
                </li>
              ))}
            </ul>
          )}
        </section>
        {showHamburger && (
          <HamburgerMenu
            className={styles.hamburger}
            onClick={() => hamburgerClick(overlay, theme, setNavOverlay, setNavTheme)}
            color={getColor(theme)}
            active={overlay}
          />
        )}
      </nav>
    </CSSTransition>
  );
};

export default MainNavigation;
