import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { Link } from 'react-router-dom';
import { get } from 'helpers/utilities';
import classNames from 'classnames/bind';

import theThingsWeMake from 'queries/theThingsWeMake';
import { components as twmcomponents } from 'queries/twm_components';

import styles from './styles.scss';

const cx = classNames.bind(styles);

const SearchFilters = ({ isSearchOpen, closeSearch }) => {
  const query = theThingsWeMake.component(twmcomponents.filters.component);
  const filterSuggestionsStyles = cx('searchOverlay__filterSuggestions', {
    'searchOverlay__filterSuggestions--hidden': !isSearchOpen
  });

  const { loading, error, data } = useQuery(query);

  if (loading || error) return null;

  const taxonomies = get(data, 'entry.filters[0]tags');

  if (!taxonomies) return null;

  return (
    <div className={filterSuggestionsStyles}>
      <span>See The work we made for</span>
      {taxonomies.length >= 1 ? (
        <ul className={styles.searchOverlay__filterList}>
          {taxonomies.map((tag, i) => {
            const { tagPageEntry } = tag;
            const { uri: tagUri, title: tagTitle } = tagPageEntry[0];

            return (
              <li key={`filter-${i}`} className={styles.searchOverlay__filterListItem}>
                <Link to={{ pathname: `/${tagUri}` }} title={tagTitle} onClick={closeSearch}>
                  <span>{tagTitle}</span>
                </Link>
              </li>
            );
          })}
        </ul>
      ) : null}
    </div>
  );
};

export default SearchFilters;
