import React, { createContext, useState, useMemo } from 'react';

const GlobalBackground = createContext({
  currentIndex: null,
  assets: [],
  setCurrentIndex: () => {},
  setAssets: () => {}
});

export const GlobalBackgroundProvider = ({ children }) => {
  const [assets, setAssets] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(null);

  const value = useMemo(
    () => ({ assets, currentIndex, setAssets, setCurrentIndex }),
    [assets.length, currentIndex]
  );
  return <GlobalBackground.Provider value={value}>{children}</GlobalBackground.Provider>;
};

export default GlobalBackground;
