import { get } from 'helpers/utilities';
import { parseGeneralContactModule } from 'queries/contactModule';
import useGlobalContent from './useGlobalContent';

const useGlobalContactContent = () => {
  const globalContent = useGlobalContent();

  const globalContactOverlayData = get(globalContent, 'globalContactOverlay');
  const generalContactModule = get(globalContactOverlayData, 'generalContactModule');

  return {
    ...globalContactOverlayData,
    ...parseGeneralContactModule(generalContactModule)
  };
};

export default useGlobalContactContent;
