import { get } from 'helpers/utilities';
import useGlobalContent from './useGlobalContent';

const useGlobalSearchContent = () => {
  const globalContent = useGlobalContent();

  return get(globalContent, 'globalSearchOverlay');
};

export default useGlobalSearchContent;
