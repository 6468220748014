import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { Link } from 'react-router-dom';
import { gql } from 'apollo-boost';

// helpers
import { get } from 'helpers/utilities';
import { stringToBold } from 'helpers/text';

const buildCategoryQuery = safeSearch => gql`
    query categoryQuery {
        categoriesConnection (search: "title:\\"${safeSearch}\\"", group: [taxonomy, output]) {
            edges {
                relatedEntries (type: PromotedTag) {
                    entries {
                        title
                        uri
                    }
                }
            }
        }
    }
`;

const SearchSuggestions = ({ safeSearch, currentSearch, closeSearch }) => {
  const { loading, error, data } = useQuery(buildCategoryQuery(safeSearch), {
    notifyOnNetworkStatusChange: true
  });

  if (loading || error) return null;

  const categories = get(data, 'categoriesConnection.edges[0].relatedEntries.entries', []);

  return (
    <div>
      {categories.length ? (
        <>
          <span>SUGGESTED</span>
          {categories.map((category, i) => {
            const { title: categoryTitle, uri } = category;
            let title = categoryTitle;
            const index = title.toLowerCase().search(safeSearch.toLowerCase());

            title = `${title.slice(0, index)}<strong>${title.slice(
              index,
              index + currentSearch.length
            )}</strong>${title.slice(index + currentSearch.length)}`;

            return (
              <Link
                key={`tag-${i}`}
                to={{ pathname: `/${uri}` }}
                title={categoryTitle}
                onClick={closeSearch}>
                <span dangerouslySetInnerHTML={{ __html: stringToBold(title) }} />
              </Link>
            );
          })}
        </>
      ) : null}
    </div>
  );
};

export default SearchSuggestions;
