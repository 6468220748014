import gql from 'graphql-tag';
import metaDetails from './metaDetails';

export default {
  main: `
        ... on Home {
            homeNavigation {
                __typename
                ... on HomeNavigationNavigationItem {
                    videoType
                    navigationLink {
                        title
                        uri
                    }
                    navigationHoverTitle
                    navigationVideoDesktop {
                        url
                        title
                    }
                    navigationVideoAssetDesktop {
                        url
                        title
                    }
                    navigationVideoTablet {
                        url
                        title
                    }
                    navigationVideoAssetTablet {
                        url
                        title
                    }
                    navigationVideoMobile {
                        url
                        title
                    }
                    navigationVideoAssetMobile {
                        url
                        title
                    }
                    navigationVideoPosterImage {
                        url
                        title
                        extension
                    }
                }
            }
            homeNavigationDefaultVideo {
                __typename
                ... on HomeNavigationDefaultVideoDefaultVideo {
                    videoType
                    navigationVideoDesktop {
                        url
                        title
                    }
                    navigationVideoAssetDesktop {
                        url
                        title
                    }
                    navigationVideoTablet {
                        url
                        title
                    }
                    navigationVideoAssetTablet {
                        url
                        title
                    }
                    navigationVideoMobile {
                        url
                        title
                    }
                    navigationVideoAssetMobile {
                        url
                        title
                    }
                    videoPosterImage {
                        url
                        title
                        extension
                    }
                    position
                }
            }
            watermark {
                watermarkAsset {
                    url
                    title
                    extension
                }
                watermarkPosition
            }
            recentNews {
                eyebrow
                image {
                    url
                    title
                }
                description
                linkType
                internalLink {
                    uri
                    title
                }
                externalLink
            }
            featuresModule {
                __typename
            }
            ${metaDetails}
        }
    `,
  component: query => gql`
        query filtersQuery {
            entry(uri: "home") {
                ... on Home {
                    ${query}
                }
            }
        }`
};
