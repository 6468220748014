import React, { useState, useCallback, useRef, useEffect } from 'react';
import ReactPlayer from 'react-player/lazy';
import Delayed from 'react-delayed';
import { withResizeDetector } from 'react-resize-detector';
import classNames from 'classnames/bind';
import deviceInfo from 'helpers/deviceInfo';
import useAccessibility from '../../hooks/useAccessibility';

import styles from './styles.scss';

const cx = classNames.bind(styles);

const Video = ({
  src,
  autoPlay,
  controls,
  loop,
  muted,
  mp4,
  webm,
  className,
  togglePlay,
  onProgress,
  title,
  fit = 'cover',
  onStart,
  width,
  height,
  headerRef,
  videoRef: _videoRef,
  ar16by9
}) => {
  const { autoplayVideos } = useAccessibility();
  const videoRef = _videoRef || useRef();
  const [playVideos, setPlayVideos] = useState(true);
  const [dimension, setDimension] = useState({
    width: '100%',
    height: '100%'
  });

  const getVideoIntrinsicDimension = useCallback(videoEl => {
    const iframe = videoEl.wrapper.querySelector('iframe');
    if (iframe) return { width: iframe.width, height: iframe.height };
    if (deviceInfo.isEdge)
      return {
        width: videoEl.wrapper.firstChild.videoWidth,
        height: videoEl.wrapper.firstChild.videoHeight
      };

    return null;
  }, []);

  const getVideoDimension = useCallback(
    (width, height) => {
      const videoElem = videoRef.current;

      let videoWidth = '100%';
      let videoHeight = '100%';

      if (!videoElem) return { width: videoWidth, height: videoHeight };

      const videoIntrinsicDimension = getVideoIntrinsicDimension(videoElem);

      if (!videoIntrinsicDimension) return { width: videoWidth, height: videoHeight };
      const videoAspectRatio = videoIntrinsicDimension.width / videoIntrinsicDimension.height;
      const containerAspectRatio = width / height;

      if (ar16by9) {
        if (width >= height) {
          videoHeight = height;
          videoWidth = height * (16 / 9);
        } else {
          videoWidth = width;
          videoHeight = width / (16 / 9);
        }
      } else if (containerAspectRatio > videoAspectRatio) {
        videoWidth = width;
        videoHeight = width / videoAspectRatio;
      } else {
        videoWidth = height * videoAspectRatio;
        videoHeight = height;
      }

      return { width: videoWidth, height: videoHeight };
    },
    [ar16by9]
  );

  const startHandler = useCallback(() => {
    setDimension(getVideoDimension(width, height));

    const iframeElem = videoRef.current.wrapper.querySelector('iframe');
    if (iframeElem && iframeElem.getAttribute('aria-hidden') !== 'true') {
      iframeElem.setAttribute('aria-hidden', 'true');
      iframeElem.setAttribute('tabindex', '-1');
    }
    if (!autoplayVideos) {
      videoRef.current.getInternalPlayer().pause();
    }
    if (onStart) {
      onStart();
    }
  }, [onStart, width, height, autoplayVideos]);

  const readyHandler = useCallback(() => {
    if (videoRef.current) {
      setDimension(getVideoDimension(width, height));
    }

    if (!headerRef) return;
    const intersectionObserver = new IntersectionObserver(
      entries => {
        const [entry] = entries;
        if (entry.isIntersecting) {
          setPlayVideos(true);
        } else {
          setPlayVideos(false);
        }
      },
      {
        root: null,
        rootMargin: '100px',
        threshold: [1, 0]
      }
    );
    intersectionObserver.observe(headerRef.current);

    return () => {
      intersectionObserver.disconnect();
    };
  }, [width, height]);

  useEffect(() => {
    setDimension(getVideoDimension(width, height));
  }, [width, height]);

  const isPlaying = togglePlay && autoplayVideos;
  const isAutoplay = autoPlay && autoplayVideos;

  const playing = isPlaying || (isAutoplay && playVideos) || false;

  if (!playing && videoRef.current) {
    const player = videoRef.current.getInternalPlayer();
    if (player && player.pause) player.pause();
  }

  const videoClassNames = cx(className, styles.video, {
    'video--cover': fit === 'cover'
  });

  return (
    <Delayed mounted={true} mountAfter={2000} unmountAfter={2000}>
      <ReactPlayer
        url={src || mp4 || webm}
        autoPlay={isAutoplay || false}
        controls={controls || false}
        loop={loop || false}
        playing={playing}
        width={dimension.width || '100%'}
        height={dimension.height || '100%'}
        playsinline={true}
        muted={muted || false}
        volume={muted ? 0 : 1}
        className={videoClassNames}
        config={{
          vimeo: {
            playerOptions: {
              autoplay: isAutoplay || false,
              byline: false,
              background: !controls,
              controls: controls || false
            }
          }
        }}
        ref={videoRef}
        onPlay={startHandler}
        onStart={startHandler}
        onProgress={onProgress}
        onReady={readyHandler}
        title={title}
      />
    </Delayed>
  );
};

export default withResizeDetector(Video);
