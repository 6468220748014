import React from 'react';
import { get } from 'helpers/utilities';
import { isStructValid } from 'helpers/structure';

const safeRender = struct => Component =>
  React.forwardRef((props, ref) => {
    if (!isStructValid(struct, get(props, 'data'))) return null;
    if (!ref) return <Component {...props} />;
    return <Component {...props} ref={ref} />;
  });

export default safeRender;
