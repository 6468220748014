import React from 'react';

const Logo = ({ fill = 'black' }) => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 199.9 15.7">
    <desc>Code and Theory Logo</desc>
    <g id="Page-1_1_">
      <g id="NAVIGATION_DESKTOP_1248" transform="translate(-64.000000, -1187.000000)">
        <g id="NAVIGATION_WHITE_1248-Copy" transform="translate(0.000000, 1117.000000)">
          <g id="Navigation" transform="translate(64.000000, 32.000000)">
            <g id="LOGO_BK" transform="translate(0.000000, 37.000000)">
              <g id="logo-text" fill={fill}>
                <g id="Group-3" transform="translate(0.000000, 0.699000)">
                  <g id="Clip-2" />
                  <defs>
                    <filter
                      id="Adobe_OpacityMaskFilter"
                      filterUnits="userSpaceOnUse"
                      x="0"
                      y="0.5"
                      width="13.2"
                      height="15.5">
                      <feColorMatrix
                        type="matrix"
                        values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0"
                      />
                    </filter>
                  </defs>
                  <mask
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0.5"
                    width="13.2"
                    height="15.5"
                    id="mask-2">
                    <g>
                      <polygon id="path-1" points="0,0.5 13.2,0.5 13.2,16 0,16" />
                    </g>
                  </mask>
                  <path
                    id="Fill-1"
                    d="M12.5,1.1c0,0.4,0.1,2.2,0.2,3.5c-0.1,0.3-0.6,0.4-0.8,0.2c-0.6-2.1-1.9-3.2-3.9-3.2
                                            c-2.3,0-4.8,2.1-4.8,6.6S5.7,15,8.2,15c2.5,0,3.7-1.9,4.2-3.5c0.2-0.2,0.7-0.1,0.8,0.2c-0.1,1.7-0.5,3-0.8,3.5
                                            C11.3,15.5,9.8,16,8.2,16c-2,0-4.1-0.5-5.6-1.7C1.1,13.1,0,11.2,0,8.4c0-2.7,1.1-4.7,2.5-5.9c1.5-1.3,3.6-2,5.7-2
                                            C10.4,0.5,11.9,1,12.5,1.1"
                  />
                </g>
                <g id="Group-6" transform="translate(13.888889, 0.699000)">
                  <g id="Clip-5" />
                  <defs>
                    <filter
                      id="Adobe_OpacityMaskFilter_1_"
                      filterUnits="userSpaceOnUse"
                      x="0.4"
                      y="0.5"
                      width="14.7"
                      height="15.5">
                      <feColorMatrix
                        type="matrix"
                        values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0"
                      />
                    </filter>
                  </defs>
                  <mask
                    maskUnits="userSpaceOnUse"
                    x="0.4"
                    y="0.5"
                    width="14.7"
                    height="15.5"
                    id="mask-4">
                    <g>
                      <polygon id="path-3" points="0.4,0.5 15.1,0.5 15.1,16 0.4,16" />
                    </g>
                  </mask>
                  <path
                    id="Fill-4"
                    d="M15.1,8.2c0,4.9-3,7.8-7.5,7.8c-4.2,0-7.2-3-7.2-7.6c0-4.4,3.1-7.9,7.4-7.9
                                            C12.1,0.5,15.1,3.6,15.1,8.2 M7.6,1.5c-2.6,0-3.9,2.6-3.9,6.3C3.7,10.6,4.6,15,8,15c2.7,0,3.8-2.9,3.8-6.5
                                            C11.8,4.6,10.2,1.5,7.6,1.5"
                  />
                </g>
                <path
                  id="Fill-7"
                  d="M32.2,5.2c0-2.1,0-2.6-1-2.7l-0.4,0c-0.2-0.1-0.2-0.8,0-0.8c1.7-0.1,3.4-0.2,5.6-0.2
                                        c1.7,0,3.4,0.2,4.8,0.7c2.7,1.1,4.2,3.7,4.2,6.5c0,3-1.3,5.4-3.7,6.7c-1.4,0.8-3.1,1.1-5.2,1.1c-1,0-2-0.1-2.8-0.1
                                        c-1,0-2.1,0-3.2,0.1c-0.2-0.1-0.2-0.7,0-0.8l0.6-0.1c0.9-0.2,1.1-0.3,1.1-2.4V5.2z M35.1,12.3c0,1.2,0,2,0.4,2.5
                                        c0.4,0.4,1,0.6,1.7,0.6c3.6,0,4.8-3.4,4.8-6.5c0-4-2-6.4-5.7-6.4c-0.6,0-1,0.1-1.1,0.3c-0.1,0.2-0.1,0.6-0.1,1.3V12.3z"
                />
                <path
                  id="Fill-9"
                  d="M51.6,7.4c0,0.6,0,0.7,0.6,0.7h1.4c1.1,0,1.3-0.2,1.5-1.2l0.1-0.6c0.1-0.2,0.7-0.2,0.8,0
                                        c0,0.7-0.1,1.5-0.1,2.4c0,0.9,0,1.7,0.1,2.4c-0.1,0.2-0.6,0.2-0.8,0l-0.1-0.6c-0.2-1.1-0.5-1.3-1.5-1.3h-1.4
                                        c-0.6,0-0.6,0-0.6,0.7v2.7c0,1,0.1,1.7,0.3,2.1c0.3,0.4,0.7,0.6,2.2,0.6c1.8,0,2.5-0.1,3.7-2.6c0.2-0.2,0.7-0.2,0.8,0.1
                                        c-0.1,1.2-0.6,3-0.9,3.6c-1.1,0-3.1-0.1-4.8-0.1h-2.7c-1,0-2,0-3.6,0.1c-0.2-0.1-0.2-0.7,0-0.8l1-0.2c0.9-0.1,1.1-0.3,1.1-2.4
                                        V4.8c0-2-0.1-2.2-1.1-2.4L47,2.3c-0.2-0.1-0.2-0.6,0-0.8c1,0,2,0.1,3,0.1h4.4c1.3,0,2.5,0,2.8-0.1c0,0.4,0.2,2,0.3,3.5
                                        c-0.1,0.2-0.6,0.3-0.8,0.1c-0.2-0.8-0.5-1.5-0.8-1.8c-0.4-0.4-1-0.7-2-0.7h-1.7c-0.7,0-0.7,0-0.7,0.9V7.4"
                />
                <path
                  id="Fill-11"
                  d="M68.3,11.3c-0.5,0-0.6,0-0.7,0.4l-0.8,2.6c-0.1,0.4-0.1,0.6-0.1,0.8c0,0.1,0.2,0.3,0.7,0.4l0.6,0.1
                                        c0.1,0.2,0.2,0.7,0,0.8c-0.7,0-1.6,0-2.4-0.1c-0.7,0-1.2,0.1-2.1,0.1c-0.2-0.1-0.2-0.6-0.1-0.8l0.6-0.1
                                        c0.5-0.1,0.9-0.2,1.3-1.1c0.4-0.9,0.7-2,1.3-3.6l1.9-5.5c0.5-1.4,0.9-2.2,0.8-2.8c1-0.2,1.8-1.3,1.8-1.3
                                        c0.3,0,0.4,0.1,0.5,0.4c0.2,0.6,0.4,1.5,0.9,3.1l2.8,8.8c0.4,1.3,0.6,1.8,1.4,2l0.8,0.1c0.1,0.1,0.2,0.6,0,0.8
                                        c-1,0-2.1-0.1-3.2-0.1c-1,0-1.8,0-2.8,0.1c-0.2-0.1-0.3-0.6,0-0.8l0.7-0.1c0.5-0.1,0.6-0.2,0.6-0.4c0-0.2-0.1-0.7-0.4-1.5
                                        l-0.6-1.9c-0.1-0.4-0.3-0.4-1-0.4H68.3z M70.5,10.1c0.5,0,0.6-0.1,0.4-0.6l-0.5-1.8c-0.3-1-0.6-1.9-0.7-2.4
                                        c-0.2,0.5-0.5,1.4-0.9,2.5l-0.5,1.7c-0.2,0.6-0.1,0.7,0.4,0.7H70.5z"
                />
                <path
                  id="Fill-13"
                  d="M90.4,12.5c0,1.5,0,2.9,0,3.6c-0.1,0.3-0.7,0.5-1.2,0.5c0,0-0.7-1.1-1.7-2.4l-4.8-6.4
                                        c-1.2-1.6-1.9-2.5-2.3-2.9c-0.1,0.2-0.1,0.6-0.1,1.4v4.3c0,1.8,0.1,3.5,0.3,4.1c0.2,0.5,0.7,0.6,1.2,0.7l0.6,0.1
                                        c0.2,0.2,0.1,0.6,0,0.8c-1.1,0-2-0.1-2.8-0.1c-0.8,0-1.5,0-2.3,0.1c-0.1-0.1-0.2-0.6,0-0.8l0.4-0.1c0.6-0.1,1-0.3,1.1-0.7
                                        c0.2-0.6,0.3-2.3,0.3-4.1V5c0-1.2,0-1.6-0.1-1.9c-0.1-0.4-0.5-0.6-1.2-0.8l-0.6-0.1c-0.1-0.2-0.1-0.7,0.1-0.8
                                        c1,0,2,0.1,2.9,0.1c0.7,0,1.3,0,1.7-0.1c0.2,1,1.6,2.7,3.4,5.1l1.7,2.2c0.9,1.2,1.5,1.9,2,2.5c0.1-0.2,0.1-0.6,0.1-1V7.2
                                        c0-1.8-0.1-3.5-0.3-4.1c-0.2-0.5-0.6-0.6-1.2-0.7l-0.6-0.1c-0.2-0.2-0.1-0.6,0-0.8c1.1,0,1.9,0.1,2.8,0.1c0.8,0,1.5,0,2.3-0.1
                                        c0.1,0.1,0.2,0.6,0,0.8l-0.4,0.1c-0.5,0.1-0.9,0.3-1.1,0.7c-0.2,0.6-0.3,2.3-0.3,4.1V12.5"
                />
                <path
                  id="Fill-15"
                  d="M94.7,5.2c0-2.1,0-2.6-1-2.7l-0.4,0c-0.2-0.1-0.2-0.8,0-0.8c1.7-0.1,3.4-0.2,5.6-0.2
                                        c1.7,0,3.4,0.2,4.8,0.7c2.7,1.1,4.2,3.7,4.2,6.5c0,3-1.3,5.4-3.7,6.7c-1.4,0.8-3.1,1.1-5.2,1.1c-1,0-2-0.1-2.8-0.1
                                        c-1,0-2.1,0-3.2,0.1c-0.2-0.1-0.2-0.7,0-0.8l0.6-0.1c0.9-0.2,1.1-0.3,1.1-2.4V5.2z M97.7,12.3c0,1.2,0,2,0.4,2.5
                                        c0.4,0.4,1,0.6,1.7,0.6c3.6,0,4.8-3.4,4.8-6.5c0-4-2-6.4-5.7-6.4c-0.6,0-1,0.1-1.1,0.3c-0.1,0.2-0.1,0.6-0.1,1.3V12.3z"
                />
                <path
                  id="Fill-17"
                  d="M119.5,3.2c0-0.5,0-0.7-0.5-0.7h-1.3c-1.5,0-1.9,0.9-2.6,2.5c-0.2,0.1-0.6,0.1-0.8-0.2
                                        c0.4-1.5,0.5-2.9,0.7-3.8c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.2,0,0.3,0.1c0.1,0.4,0.2,0.5,1.7,0.5h7.7c1.4,0,1.7,0,1.9-0.5
                                        c0.1,0,0.1-0.1,0.3-0.1c0.1,0,0.3,0.1,0.3,0.2c-0.2,0.8-0.4,2.6-0.3,3.9c-0.1,0.2-0.7,0.2-0.8,0.1c-0.4-1.4-0.6-2.5-2.2-2.5
                                        H123c-0.5,0-0.6,0.1-0.6,0.7v9.9c0,2.1,0.1,2.2,1.1,2.4l0.8,0.1c0.1,0.1,0.1,0.6,0,0.8c-1.3,0-2.4-0.1-3.3-0.1
                                        c-1,0-2.1,0-3.5,0.1c-0.2-0.1-0.2-0.6,0-0.8l0.9-0.1c0.9-0.2,1.1-0.3,1.1-2.4V3.2"
                />
                <path
                  id="Fill-19"
                  d="M133.8,9.2c-0.8,0-0.9,0-0.9,0.6v3.2c0,2.1,0.1,2.2,1.1,2.4l0.7,0.1c0.2,0.1,0.2,0.6,0,0.8
                                        c-1.2,0-2.2-0.1-3.2-0.1c-1,0-2,0-3.1,0.1c-0.2-0.1-0.2-0.6,0-0.8l0.5-0.1c0.9-0.2,1.1-0.4,1.1-2.4V4.8c0-2.1-0.1-2.2-1.1-2.4
                                        l-0.5-0.1c-0.2-0.1-0.2-0.6,0-0.8c1,0,2.1,0.1,3.1,0.1c0.9,0,2,0,2.9-0.1c0.2,0.1,0.2,0.7,0,0.8l-0.4,0.1
                                        c-0.9,0.2-1,0.4-1,2.4v2.6c0,0.6,0,0.7,0.9,0.7h4.3c0.9,0,0.9,0,0.9-0.7V4.8c0-2.1-0.1-2.2-1.1-2.4l-0.6-0.1
                                        c-0.2-0.1-0.2-0.6,0-0.8c1.1,0,2.2,0.1,3.2,0.1c1,0,2,0,2.9-0.1c0.2,0.1,0.2,0.7,0,0.8L143,2.3c-1,0.2-1.1,0.4-1.1,2.4v8.3
                                        c0,2.1,0.1,2.2,1.1,2.4l0.5,0.1c0.2,0.1,0.2,0.6,0,0.8c-1,0-2.1-0.1-3-0.1c-1,0-2.1,0-3,0.1c-0.2-0.1-0.2-0.6,0-0.8l0.5-0.1
                                        c1-0.2,1.1-0.4,1.1-2.4V9.9c0-0.6,0-0.6-0.9-0.6H133.8"
                />
                <path
                  id="Fill-21"
                  d="M149.6,7.4c0,0.6,0,0.7,0.6,0.7h1.4c1.1,0,1.3-0.2,1.5-1.2l0.1-0.6c0.1-0.2,0.7-0.2,0.8,0
                                        c0,0.7-0.1,1.5-0.1,2.4c0,0.9,0,1.7,0.1,2.4c-0.1,0.2-0.6,0.2-0.8,0l-0.1-0.6c-0.2-1.1-0.5-1.3-1.5-1.3h-1.4
                                        c-0.6,0-0.6,0-0.6,0.7v2.7c0,1,0.1,1.7,0.3,2.1c0.3,0.4,0.7,0.6,2.2,0.6c1.8,0,2.5-0.1,3.6-2.6c0.2-0.2,0.7-0.2,0.8,0.1
                                        c-0.1,1.2-0.6,3-0.9,3.6c-1.1,0-3.1-0.1-4.8-0.1h-2.7c-1,0-2,0-3.6,0.1c-0.2-0.1-0.2-0.7,0-0.8l1-0.2c0.9-0.1,1.1-0.3,1.1-2.4
                                        V4.8c0-2-0.1-2.2-1.1-2.4l-0.5-0.1c-0.2-0.1-0.2-0.6,0-0.8c1,0,2,0.1,3,0.1h4.4c1.3,0,2.5,0,2.8-0.1c0,0.4,0.2,2,0.3,3.5
                                        c-0.1,0.2-0.6,0.3-0.8,0.1c-0.2-0.8-0.5-1.5-0.8-1.8c-0.4-0.4-1-0.7-2-0.7h-1.7c-0.7,0-0.7,0-0.7,0.9V7.4"
                />
                <g id="Group-25" transform="translate(157.407407, 0.699000)">
                  <g id="Clip-24" />
                  <defs>
                    <filter
                      id="Adobe_OpacityMaskFilter_2_"
                      filterUnits="userSpaceOnUse"
                      x="0"
                      y="0.5"
                      width="14.7"
                      height="15.5">
                      <feColorMatrix
                        type="matrix"
                        values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0"
                      />
                    </filter>
                  </defs>
                  <mask
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0.5"
                    width="14.7"
                    height="15.5"
                    id="mask-6">
                    <g>
                      <polygon id="path-5" points="0,0.5 14.7,0.5 14.7,16 0,16" />
                    </g>
                  </mask>
                  <path
                    id="Fill-23"
                    d="M14.7,8.2c0,4.9-3,7.8-7.5,7.8C3.1,16,0,13,0,8.4c0-4.4,3.1-7.9,7.4-7.9
                                            C11.7,0.5,14.7,3.6,14.7,8.2 M7.3,1.5c-2.6,0-3.9,2.6-3.9,6.3c0,2.7,0.9,7.1,4.3,7.1c2.7,0,3.8-2.9,3.8-6.5
                                            C11.4,4.6,9.8,1.5,7.3,1.5"
                  />
                </g>
                <path
                  id="Fill-26"
                  d="M175.3,5.2c0-2.2,0-2.5-0.9-2.6l-0.6-0.1c-0.2-0.1-0.2-0.7,0-0.8c1.6-0.1,3.2-0.2,5.1-0.2
                                        c1.8,0,3.3,0.3,4.1,0.8c1.2,0.8,1.8,1.9,1.8,3.3c0,2-1.2,2.8-2.2,3.4c-0.2,0.1-0.2,0.2-0.1,0.5c1.4,3,2.2,4.7,3.2,5.6
                                        c0.5,0.5,1.1,0.6,1.4,0.6c0.2,0.1,0.2,0.4,0.1,0.6c-0.3,0.2-1,0.2-1.6,0.2c-1.9,0-3.1-0.7-4-2.4c-0.7-1.2-1.1-2.3-1.6-3.5
                                        c-0.2-0.6-0.6-0.8-1.1-0.8c-0.7,0-0.8,0-0.8,0.5v2.8c0,2.1,0.1,2.2,1.1,2.4l0.6,0.1c0.1,0.1,0.1,0.6,0,0.8
                                        c-1.1,0-2.1-0.1-3-0.1c-1,0-1.9,0-3,0.1c-0.2-0.1-0.2-0.7,0-0.8l0.6-0.1c0.9-0.2,1.1-0.3,1.1-2.4V5.2z M178.2,8
                                        c0,0.8,0.1,0.9,1.3,0.9c0.7,0,1.2-0.2,1.6-0.6c0.4-0.4,0.7-1.1,0.7-2.4c0-2-1.1-3.3-2.7-3.3c-0.9,0-0.9,0.1-0.9,1.1V8z"
                />
                <path
                  id="Fill-28"
                  d="M194.3,13.1c0,2.1,0.1,2.2,1.1,2.4l0.8,0.1c0.1,0.1,0.1,0.6,0,0.8c-1.3,0-2.4-0.1-3.4-0.1
                                        c-1,0-2,0-3.3,0.1c-0.2-0.1-0.2-0.6,0-0.8l0.7-0.1c0.9-0.2,1.1-0.3,1.1-2.4V12c0-1.1,0-1.5-0.6-2.7l-2.5-5.5
                                        c-0.4-0.9-0.6-1.3-1.3-1.4l-0.7-0.1c-0.2-0.2-0.2-0.7,0.1-0.8c0.9,0,1.7,0.1,2.9,0.1c1.3,0,2.3,0,3-0.1c0.2,0.1,0.2,0.6,0,0.8
                                        l-0.7,0.2c-0.4,0.1-0.5,0.2-0.5,0.2c0,0.2,0.1,0.5,0.5,1.6l1,2.4c0.3,0.7,0.7,1.6,1,2.2c0.6-1.1,1.2-2.4,1.7-3.5
                                        c0.8-1.6,1.1-2.3,1.1-2.6c0-0.1-0.2-0.2-0.7-0.3l-0.5-0.1c-0.2-0.1-0.2-0.7,0-0.8c0.8,0,1.6,0.1,2.3,0.1c0.8,0,1.4,0,2-0.1
                                        c0.2,0.1,0.2,0.6,0.1,0.8l-0.5,0.1c-0.4,0.1-0.8,0.2-1,0.5c-0.3,0.4-0.8,1.2-1.8,3.2l-1.3,2.6c-0.5,1.1-0.7,1.6-0.7,2.9V13.1"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default Logo;
