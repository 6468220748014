import { useContext, useCallback } from 'react';
import Navigation, {
  SET_NAVIGATION_VISIBLE,
  SET_NAVIGATION_THEME,
  SET_NAVIGATION_OVERLAY,
  SET_NAVIGATION_ACTIVE_LINK,
  SET_NAVIGATION_GRADIENT,
  SET_ACTIVE_ID,
  SET_NAVIGATION_TOGGLING,
  SET_NAVIGATION_SHOW_PRIMARY_LINKS,
  SET_NAVIGATION_SHOW_LOGO
} from '../contexts/Navigation';

const useNavigation = () => {
  const { state, dispatch } = useContext(Navigation);

  const setNavVisible = useCallback(
    visible => dispatch({ type: SET_NAVIGATION_VISIBLE, payload: visible }),
    [dispatch]
  );
  const setNavTheme = useCallback(
    theme => dispatch({ type: SET_NAVIGATION_THEME, payload: theme }),
    [dispatch]
  );
  const setNavOverlay = useCallback(
    overlay => dispatch({ type: SET_NAVIGATION_OVERLAY, payload: overlay }),
    [dispatch]
  );
  const setNavActiveLink = useCallback(
    link => dispatch({ type: SET_NAVIGATION_ACTIVE_LINK, payload: link }),
    [dispatch]
  );
  const setNavGradient = useCallback(
    gradient => dispatch({ type: SET_NAVIGATION_GRADIENT, payload: gradient }),
    [dispatch]
  );
  const setNavToggling = useCallback(
    toggling => dispatch({ type: SET_NAVIGATION_TOGGLING, payload: toggling }),
    [dispatch]
  );
  const setActiveID = useCallback(
    activeID => dispatch({ type: SET_ACTIVE_ID, payload: activeID }),
    [dispatch]
  );
  const setShowPrimaryLinks = useCallback(
    showPrimary => dispatch({ type: SET_NAVIGATION_SHOW_PRIMARY_LINKS, payload: showPrimary }),
    [dispatch]
  );
  const setShowLogo = useCallback(
    showLogo => dispatch({ type: SET_NAVIGATION_SHOW_LOGO, payload: showLogo }),
    [dispatch]
  );

  return {
    state,
    setNavVisible,
    setNavTheme,
    setNavOverlay,
    setNavActiveLink,
    setNavGradient,
    setNavToggling,
    setActiveID,
    setShowPrimaryLinks,
    setShowLogo
  };
};

export default useNavigation;
