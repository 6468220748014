import React, { createContext, useReducer, useEffect, useRef, useMemo } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

import { EVENT } from 'config/constants';
import EventHandler from 'helpers/EventHandler';
import { getThrottledRAF } from 'helpers/layout';
import useAccessibility from '../hooks/useAccessibility';

const initialState = {
  visible: true,
  theme: 'white',
  toggling: true,
  overlay: false,
  activeLink: '',
  gradient: true,
  showPrimaryLinks: true,
  showLogo: true
};

export const SET_NAVIGATION_VISIBLE = 'SET_NAVIGATION_VISIBLE';
export const SET_NAVIGATION_THEME = 'SET_NAVIGATION_THEME';
export const SET_NAVIGATION_TOGGLING = 'SET_NAVIGATION_TOGGLING';
export const SET_NAVIGATION_OVERLAY = 'SET_NAVIGATION_OVERLAY';
export const SET_NAVIGATION_ACTIVE_LINK = 'SET_NAVIGATION_ACTIVE_LINK';
export const SET_NAVIGATION_GRADIENT = 'SET_NAVIGATION_GRADIENT';
export const SET_NAVIGATION_SHOW_PRIMARY_LINKS = 'SET_NAVIGATION_SHOW_PRIMARY_LINKS';
export const SET_NAVIGATION_SHOW_LOGO = 'SET_NAVIGATION_SHOW_LOGO';

// TO DO CCR-2895 Previous MainNavigationState that is use only
// on careers job container, maybe we could isolate this to navigation
export const SET_ACTIVE_ID = 'SET_ACTIVE_ID';

const reducer = (state, { type, payload }) => {
  switch (type) {
    case SET_NAVIGATION_VISIBLE:
      return { ...state, visible: payload };
    case SET_NAVIGATION_THEME:
      return { ...state, theme: payload };
    case SET_NAVIGATION_TOGGLING:
      return { ...state, toggling: payload };
    case SET_NAVIGATION_OVERLAY:
      return { ...state, overlay: payload };
    case SET_NAVIGATION_ACTIVE_LINK:
      return { ...state, activeLink: payload };
    case SET_NAVIGATION_GRADIENT:
      return { ...state, gradient: payload };
    case SET_ACTIVE_ID:
      return { ...state, activeID: payload };
    case SET_NAVIGATION_SHOW_PRIMARY_LINKS:
      return { ...state, showPrimaryLinks: payload };
    case SET_NAVIGATION_SHOW_LOGO:
      return { ...state, showLogo: payload };
    default:
      return state;
  }
};

const Navigation = createContext({
  state: initialState,
  dispatch: () => {}
});

export const NavigationProvider = ({ children }) => {
  const location = useLocation();
  const history = useHistory();
  const [state, dispatch] = useReducer(reducer, initialState);
  const { visible, toggling } = state;
  const _visbile = useRef(visible);
  const { isClientPassingThroughLinks } = useAccessibility();

  useEffect(() => {
    const throttledRAF = getThrottledRAF();
    const scrollHandler = (_, e) => {
      if (!toggling) return;
      if (e.direction === 'down') {
        if (!_visbile.current) {
          dispatch({ type: SET_NAVIGATION_VISIBLE, payload: true });
        }
      } else if (e.direction === 'up' && e.scrollY > 90) {
        if (_visbile.current) {
          dispatch({ type: SET_NAVIGATION_VISIBLE, payload: false });
        }
      }
    };
    const scrollEvent = EventHandler.subscribe(EVENT.SCROLL, (_, e) =>
      throttledRAF(() => scrollHandler(_, e))
    );

    return () => EventHandler.unsubscribe(scrollEvent);
  }, [toggling]);

  // use a ref and effect combo
  // for perfomance of scroll effect above
  useEffect(() => {
    _visbile.current = visible;

    const navEl = document.querySelector('nav');
    const dispatchListener = () => {
      if (isClientPassingThroughLinks && navEl && navEl.matches(':focus-within')) {
        if (!_visbile.current) {
          dispatch({ type: SET_NAVIGATION_VISIBLE, payload: true });
        }
      } else if (isClientPassingThroughLinks && navEl && !navEl.matches(':focus-within')) {
        if (_visbile.current && window.scrollY > 90) {
          dispatch({ type: SET_NAVIGATION_VISIBLE, payload: false });
        }
      }
    };

    window.addEventListener('keyup', dispatchListener);

    return () => window.removeEventListener('keyup', dispatchListener);
  }, [state.visible, isClientPassingThroughLinks]);

  useEffect(() => {
    dispatch({ type: SET_NAVIGATION_ACTIVE_LINK, payload: location.pathname });
    return history.listen(newLocation => {
      dispatch({ type: SET_NAVIGATION_ACTIVE_LINK, payload: newLocation.pathname });
    });
  }, []);

  useEffect(() => {
    dispatch({ type: SET_NAVIGATION_VISIBLE, payload: true });
    dispatch({ type: SET_NAVIGATION_OVERLAY, payload: false });
    dispatch({ type: SET_NAVIGATION_ACTIVE_LINK, payload: location.pathname });
  }, [location.pathname]);

  const value = useMemo(() => ({ state, dispatch }), [JSON.stringify(state)]);

  return <Navigation.Provider value={value}>{children}</Navigation.Provider>;
};

export default Navigation;
