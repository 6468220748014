import articleHeaders from './articleHeader';
import metaDetails from './metaDetails';

export default `
    query pageQuery {
        globals {
            globalContactOverlay {
                generalContactModule {
                    ... on GeneralContactModuleGeneralContact {
                        contactTitle {
                            content
                        }
                        findUs {
                            contactTitle
                            contactAddress {
                                content
                            }
                            contactLink {
                                uri
                                title
                            }
                            contactCtaTitle
                            contactComponentLink
                        }
                        workWithUs {
                            contactTitle
                            contactEmail
                        }
                        press {
                            contactTitle
                            contactEmail
                        }
                        careers {
                            contactTitle
                            contactEmail
                            contactLink {
                                uri
                                title
                            }
                            contactCtaTitle
                        }
                    }
                }
                newsletterSignup {
                    ... on NewsletterSignupNewsletterSignupEntry {
                    newsletterSignupLogo {
                        url
                    }
                    newsletterSignupDescription
                    }
                }
                socialLinks {
                    ... on SocialLinksSocialLinksEntries {
                    socialLinkUrl
                    socialLinkType
                    }
                }
                legalLinks {
                    ... on LegalLinksLegalLinksEntries {
                        legalLinkEntry {
                            title
                            uri
                            slug
                        }
                    }
                }
                secondaryLinks {
                    ... on SecondaryLinksSuperTableBlockType {
                        secondaryLinkCta {
                            content
                        }
                        secondaryLink
                    }
                }
            }
            globalSearchOverlay {
                searchSuggestions {
                    suggestionsTitle {
                        content
                    }
                    suggestedContent {
                        title
                        uri
                        postDate
                        __typename
                        ... on CaseStudiesCaseStudy {
                            ${articleHeaders.caseStudy}
                        }
                        ... on ThoughtLeadership {
                            ${articleHeaders.thoughtLeadership}
                        }
                        ... on CuratedWorkCuration {
                            ${articleHeaders.curatedWork}
                        }
                        ... on News {
                            ${articleHeaders.news}
                        }
                        ... on Press {
                            ${articleHeaders.press}
                        }
                        ... on Event {
                            ${articleHeaders.event}
                        }
                    }
                }
            }
            globalMetaDetails {
                ${metaDetails}
            }
        }
        sites (handle: "default") {
            language
        }
    }
`;
