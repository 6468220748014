import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';

import global from '../queries/global';

const useGlobalContent = () => {
  const { data = { globals: {} } } = useQuery(
    gql`
      ${global}
    `
  );
  return { ...data.globals, sites: data.sites };
};

export default useGlobalContent;
