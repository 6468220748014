import loadable from '@loadable/component';

const fallback = () => null;
const Home = loadable(() => import(/* webpackChunkName: "CandtHome" */ '../../pages/Home'), {
  fallback
});
const WhyWeMake = loadable(
  () => import(/* webpackChunkName: "CandtWhyWeMake" */ '../../pages/WhyWeMake'),
  { fallback }
);
const ThingsWeMake = loadable(
  () => import(/* webpackChunkName: "CandtThingsWeMake" */ '../../pages/ThingsWeMake'),
  { fallback }
);
const SaysALot = loadable(
  () => import(/* webpackChunkName: "CandtSaysALot" */ '../../pages/SaysALot'),
  { fallback }
);
const AboutUs = loadable(
  () => import(/* webpackChunkName: "CandtAboutUs" */ '../../pages/AboutUs'),
  { fallback }
);
const Careers = loadable(
  () => import(/* webpackChunkName: "CandtCareers" */ '../../pages/Careers'),
  { fallback }
);

const transporterLogic = {
  home: {
    nextTemplate: 'thingsWeMake',
    nextTemplateUrl: '/things-we-make',
    pageTitle: 'Homepage',
    url: '/',
    NextComponent: Home
  },
  whyWeMake: {
    nextTemplate: 'thingsWeMake',
    nextTemplateUrl: '/things-we-make',
    pageTitle: 'Why We Make',
    url: '/why-we-make',
    NextComponent: WhyWeMake
  },
  thingsWeMake: {
    nextTemplate: 'saysALot',
    nextTemplateUrl: '/says-a-lot',
    pageTitle: 'The Things We Make',
    url: '/things-we-make',
    NextComponent: ThingsWeMake
  },
  caseStudy: {
    nextTemplate: 'thingsWeMake',
    nextTemplateUrl: '/things-we-make',
    pageTitle: 'Project',
    NextComponent: ThingsWeMake
  },
  service: {
    nextTemplate: 'aboutUs',
    nextTemplateUrl: '/about-us',
    pageTitle: 'Service',
    NextComponent: AboutUs
  },
  news: {
    nextTemplate: 'saysALot',
    nextTemplateUrl: '/says-a-lot',
    pageTitle: 'News',
    NextComponent: SaysALot
  },
  press: {
    nextTemplate: 'saysALot',
    nextTemplateUrl: '/says-a-lot',
    pageTitle: 'Press',
    NextComponent: SaysALot
  },
  event: {
    nextTemplate: 'saysALot',
    nextTemplateUrl: '/says-a-lot',
    pageTitle: 'Event',
    NextComponent: SaysALot
  },
  saysALot: {
    nextTemplate: 'aboutUs',
    nextTemplateUrl: '/about-us',
    pageTitle: 'Says a Lot',
    url: '/says-a-lot',
    NextComponent: SaysALot
  },
  aboutUs: {
    nextTemplate: 'careers',
    nextTemplateUrl: '/careers',
    pageTitle: 'About Us',
    url: '/about-us',
    NextComponent: AboutUs
  },
  careers: {
    nextTemplate: 'aboutUs',
    nextTemplateUrl: '/about-us',
    pageTitle: 'Careers',
    url: '/careers',
    NextComponent: Careers
  },
  location: {
    nextTemplate: 'aboutUs',
    nextTemplateUrl: '/about-us',
    pageTitle: 'Location',
    NextComponent: AboutUs
  },
  careerCategoryDetail: {
    nextTemplate: 'aboutUs',
    nextTemplateUrl: '/about-us',
    pageTitle: 'Career Detail',
    NextComponent: AboutUs
  },
  jobDetail: {
    nextTemplate: 'careers',
    nextTemplateUrl: '/careers',
    pageTitle: 'Job Detail',
    NextComponent: Careers
  }
};

export default transporterLogic;
