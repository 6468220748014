import React from 'react';
import classNames from 'classnames/bind';

import { includes } from 'helpers/utilities';

import useNavigation from '../../hooks/useNavigation';

import styles from './styles.scss';

const cx = classNames.bind(styles);

const HamburgerMenu = ({ active = false, onClick, className, color = 'white' }) => {
  const hamburgerClass = cx('hamburger', `hamburger--${color}`, {
    'hamburger--active': active
  });

  const {
    state: { activeLink }
  } = useNavigation();
  const isHome = includes(['', '/'], activeLink);

  return (
    <button
      type="button"
      className={`${className}${isHome ? '__home' : '__others'}`}
      onClick={onClick}
      aria-label="Hamburger Menu">
      <span className={hamburgerClass}>
        <span className={`${styles.hamburger__inner}`} />
      </span>
    </button>
  );
};

export default HamburgerMenu;
