import articleHeaders from './articleHeader';
import { commonArticleContent } from './saysALotContent';

const cardQueries = {
  article: `
        ... on ThoughtLeadership {
            uri
            title
            postDate
            cardArticle {
                __typename
                ... on CardArticleCard {
                    textColor
                    caption {
                        content
                    }
                    description
                    featureCardArtwork {
                        url
                        title
                        extension
                    }
                    standardCardArtwork {
                        url
                        title
                        extension
                    }
                    floatingCardArtwork {
                        url
                        title
                        extension
                    }
                }
            }
        }
    `,
  curatedWork: `
        ... on CuratedWorkCuration {
            cardArticle {
                __typename
                ... on CardArticleCard {
                    textColor
                    caption {
                        content
                    }
                    description
                    featureCardArtwork {
                        url
                        title
                        extension
                    }
                    standardCardArtwork {
                        url
                        title
                        extension
                    }
                }
            }
        }
    `,
  singlePage: `
        ... on SinglePage {
            cardArticle {
                __typename
                ... on CardArticleCard {
                    caption {
                        content
                    }
                    description
                    featureCardArtwork {
                        url
                        title
                        extension
                    }
                    standardCardArtwork {
                        url
                        title
                        extension
                    }
                    floatingCardArtwork {
                        url
                        title
                        extension
                    }
                }
            }
        }
		`,
  project: `
        ... on CaseStudiesCaseStudy {
            cardProject {
                __typename
                ... on CardProjectCard {
                    textColor
                    caption
                    description
                    featureCardArtwork {
                        url
                        title
                        extension
                    }
                    standardCardArtwork {
                        url
                        title
                        extension
                    }
                    floatingCardArtwork {
                        url
                        title
                        extension
                    }
                }
            }
        }
    `,
  account: `
        ... on AccountsAccount {
            cardAccount {
                ... on CardAccountCard {
                    cardArtwork {
                        url
                        title
                    }
                    accountLogo {
                        url
                        title
                        extension
                    }
                }
            }
        }
    `,
  event: `
        ... on Event {
            ${articleHeaders.event}
            ${commonArticleContent}
        }
    `,
  press: `
        ... on Press {
            ${articleHeaders.press}
            ${commonArticleContent}
        }
    `,
  news: `
        ... on News {
            ${articleHeaders.news}
            ${commonArticleContent}
        }
    `,
  services: `
        ... on Services {
            cardProject {
                __typename
                ... on CardProjectCard {
                    textColor
                    caption
                    description
                    featureCardArtwork {
                        url
                        title
                        extension
                    }
                    standardCardArtwork {
                        url
                        title
                        extension
                    }
                    floatingCardArtwork {
                        url
                        title
                        extension
                    }
                }
            }
        }
    `
};

export default cardQueries;
