import { useEffect, useRef, useCallback } from 'react';
import EventHandler from 'helpers/EventHandler';
import { EVENT } from 'config/constants';

const GridGuide = () => {
  const showGridRef = useRef(false);
  const keyDownEventRef = useRef();

  const toggleGridGuide = useCallback((_, e) => {
    if (e.ctrlKey && e.keyCode === 71) {
      showGridRef.current = !showGridRef.current;
      document.body.classList.toggle('showGrid', showGridRef.current);
    }
  }, []);

  // componentDidMount
  useEffect(() => {
    keyDownEventRef.current = EventHandler.subscribe(EVENT.KEYDOWN, toggleGridGuide);

    // Cleanup
    return () => {
      EventHandler.unsubscribe(keyDownEventRef.current);
    };
  }, []);

  return null;
};

export default GridGuide;
