import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames/bind';

import { get } from 'helpers/utilities';
import { stringToBold } from 'helpers/text';
import { pickArticleHeaderContent, pickArticleHeaderEyebrow } from 'queries/articleHeader';

import styles from './styles.scss';

const cx = classNames.bind(styles);

const ArticleSuggestions = ({ dataSearchOverlay, currentSearch, closeSearch }) => {
  if (!dataSearchOverlay) return null;

  const { suggestionsTitle, suggestedContent } = get(dataSearchOverlay, 'searchSuggestions[0]');
  const suggestionsStyles = cx('searchOverlay__suggestions', {
    'searchOverlay__suggestions--hidden': currentSearch.length > 0
  });
  return (
    <div className={suggestionsStyles}>
      <h3
        className={styles.searchOverlay__suggestions__title}
        dangerouslySetInnerHTML={{ __html: stringToBold(get(suggestionsTitle, 'content')) }}
      />
      {suggestedContent.map((entry, i) => {
        let entryTitle = '';
        const entryType = get(entry, '__typename');
        if (entryType === 'CaseStudiesCaseStudy') {
          const articleTitle = pickArticleHeaderContent(entry);
          const eyebrow = pickArticleHeaderEyebrow(entry);
          entryTitle = `<strong>${eyebrow}</strong> | ${articleTitle}`;
        } else if (entryType === 'ThoughtLeadership' || entryType === 'CuratedWorkCuration') {
          entryTitle = pickArticleHeaderContent(entry);
        } else if (entryType === 'Event' || entryType === 'News' || entryType === 'Press') {
          const articleTitle = pickArticleHeaderContent(entry);
          entryTitle = `${entryType} | ${articleTitle}`;
        }

        return (
          <Link
            key={i}
            to={{ pathname: `/${get(entry, 'uri')}` }}
            title={get(entry, 'title')}
            onClick={closeSearch}>
            <h4
              className={styles.searchOverlay__suggestions__entry}
              dangerouslySetInnerHTML={{ __html: stringToBold(entryTitle) }}
            />
          </Link>
        );
      })}
    </div>
  );
};

export default ArticleSuggestions;
