import React, { createContext, useState, useEffect, useMemo, useRef } from 'react';
import ReactDOM from 'react-dom';
import { useLocation } from 'react-router-dom';

// helpers
import canUseDOM from 'helpers/canUseDOM';

// components
import ContactOverlay from 'components/ContactOverlay';
import SearchOverlay from 'components/SearchOverlay';

import usePrevious from '../hooks/usePrevious';

const Overlay = createContext({
  overlay: '',
  setOverlay: () => {},
  overlayIds: {},
  triggeredId: null,
  setTriggeredId: () => {}
});

const overlays = ['/contact', '/search'];

export const OverlayProvider = ({ children }) => {
  const [overlay, setOverlay] = useState('');
  const location = useLocation();
  const prevLocation = usePrevious(location);
  const overlayIds = useRef({
    contactHP: 'contact-hp',
    searchHP: 'search-hp',
    contactIP: 'contact-ip',
    searchIP: 'search-ip',
    contactMob: 'contact-mob',
    searchMob: 'search-mob'
  });
  const [triggeredId, setTriggeredId] = useState(null);

  useEffect(() => {
    // set overlay if current pathname is on overlay list
    if (overlays.some(overlayItem => overlayItem === location.pathname)) {
      const pathname = location.pathname.slice(1);
      if (pathname !== overlay) setOverlay(pathname);
    }
  }, [location.pathname, overlay]);

  useEffect(() => {
    // unset overlay if current location is not on the overlay list and the previous pathname is an overlay
    if (
      prevLocation &&
      location.pathname !== prevLocation.pathname &&
      overlays.some(overlayItem => overlayItem === prevLocation.pathname)
    ) {
      setOverlay('');
    }
  }, [location.pathname]);

  const value = useMemo(
    () => ({
      overlay,
      setOverlay,
      overlayIds,
      setTriggeredId,
      triggeredId
    }),
    [overlay, triggeredId]
  );

  return (
    <Overlay.Provider value={value}>
      {children}
      {canUseDOM
        ? ReactDOM.createPortal(
            <>
              <ContactOverlay overlay={overlay} setOverlay={setOverlay} />
              <SearchOverlay overlay={overlay} setOverlay={setOverlay} />
            </>,
            document.getElementById('overlay-portal')
          )
        : null}
    </Overlay.Provider>
  );
};

export default Overlay;
