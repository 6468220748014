import { useRef, useEffect } from 'react';
import { debounce } from 'helpers/utilities';

const useDebounce = () => {
  const debounces = useRef([]);

  useEffect(
    () => () => {
      debounces.current.forEach(fn => fn.cancel());
      debounces.current = [];
    },
    []
  );

  return (cb, delay) => {
    const flush = debounce(cb, delay);
    debounces.current.push(flush);
    return flush;
  };
};

export default useDebounce;
