import { struct, StructError } from 'superstruct';

export const wysiwygStruct = struct.partial({
  content: 'string'
});

export const assetStruct = struct.tuple([
  struct.partial({
    url: 'string',
    title: 'string?'
  })
]);

export const linkStruct = struct.tuple([
  struct.partial({
    uri: 'string'
  })
]);

export const cardStruct = struct.partial({
  card: struct.tuple(['any'])
});

export const isStructValid = (structure, data) =>
  !(structure.validate(data)[0] instanceof StructError);
