import React from 'react';

import styles from './styles.scss';

const SearchResultCount = ({ totalCount = 0, isSearchQueryLoading }) => {
  if (isSearchQueryLoading) return null;

  return (
    <div>
      {totalCount ? (
        <p className={styles.searchOverlay__resultCount}>{`${totalCount} Result${
          totalCount > 1 ? 's' : ''
        }`}</p>
      ) : null}
    </div>
  );
};

export default SearchResultCount;
