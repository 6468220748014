import React, { useEffect, useRef, useCallback } from 'react';
import { CSSTransition } from 'react-transition-group';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { isBodyScrollDisabled, disableBodyScroll, enableBodyScroll } from 'helpers/scroll';

// a11y
import maintainTabFocus from 'ally.js/maintain/tab-focus';
import queryFirstTabbable from 'ally.js/query/first-tabbable';
import whenKey from 'ally.js/when/key';
import visibleArea from 'ally.js/when/visible-area';

import canUseDOM from 'helpers/canUseDOM';

// components
import Newsletter from 'components/Newsletter';
import Icon from 'components/Icon';
import Image from 'components/Image';

// helpers
import { stringToBold, capitalize } from 'helpers/text';
import { socialClick } from 'helpers/dataLayer';
import { get } from 'helpers/utilities';

// hooks
import useTimeout from '../../hooks/useTimeout';
import useGlobalContactContent from '../../hooks/useGlobalContactContent';

import styles from './styles.scss';

/**
 * TO FIX:
 * For some reason, there is an inifinite loop in
 * GlobalComponentWrappers's mapDispatchToProps
 */

const ContactOverlay = ({ overlay, setOverlay }) => {
  const isOpen = overlay === 'contact';

  const data = useGlobalContactContent();

  const {
    contactTitle,
    contactOptions = [],
    newsletterSignup,
    socialLinks = [],
    legalLinks = []
  } = data;

  const history = useHistory();
  const location = useLocation();
  const timeout = useTimeout();

  const canClose = useRef(false);
  const activeElement = useRef(null);
  const hiddenHandle = useRef(null);
  const keyHandle = useRef(null);
  const tabHandle = useRef(null);
  const contactOverlayRef = useRef();

  useEffect(() => {
    if (canUseDOM && isOpen && !isBodyScrollDisabled()) {
      disableBodyScroll({ overlay: true });
    } else if (canUseDOM && !isOpen && isBodyScrollDisabled()) {
      enableBodyScroll({ overlay: true });
    }
  }, [isOpen]);

  // componentDidUpdate
  useEffect(() => {
    if (isOpen) {
      _addAlly();
    } else {
      _removeAlly();
    }
  }, [isOpen]);

  const onEntered = useCallback(() => {
    canClose.current = true;
    if (location.pathname !== '/contact') history.push('/contact');
  }, [location.pathname]);

  const onClickClose = useCallback(() => {
    if (!canClose) return;

    canClose.current = false;

    const state = get(window, 'history.state');

    if (state) history.goBack();
    else history.push('/');
  }, []);

  function _addAlly() {
    activeElement.current = document.activeElement;

    // Will execute the callback once the Overlay is visible.
    visibleArea({
      context: contactOverlayRef.current,
      callback: context => {
        /**
         * Find the first focusable element (giving any element
         * with autofocus attribute precendence).
         */
        const firstFocusableElement = queryFirstTabbable({
          context,
          defaultToContext: true
        });

        if (firstFocusableElement) {
          firstFocusableElement.focus();
        }
      }
    });

    timeout(() => {
      /**
       * React to ESC Key
       * Overlay will close when ESC Key is pressed.
       */
      keyHandle.current = whenKey({
        escape: () => setOverlay('')
      });

      /**
       * Make sure the Tab Key controlled focus is trapped within
       * the tab sequence of the Overlay and doesn't reach
       * the browser's UI.
       */
      tabHandle.current = maintainTabFocus({
        context: contactOverlayRef.current
      });
    }, 500);
  }

  function _removeAlly() {
    if (hiddenHandle.current) {
      hiddenHandle.current.disengage();
    }

    if (keyHandle.current) {
      keyHandle.current.disengage();
    }

    if (tabHandle.current) {
      tabHandle.current.disengage();
    }

    if (activeElement.current) {
      activeElement.current.focus();
    }
  }

  return (
    <CSSTransition
      in={isOpen}
      timeout={1500}
      classNames={styles.contactOverlay}
      unmountOnExit={true}
      onEntered={() => onEntered()}>
      <section
        role="dialog"
        ref={contactOverlayRef}
        className={styles.contactOverlay}
        aria-label="Contact Overlay"
        aria-describedby="contact-overlay-title">
        <button
          type="button"
          className={styles.contactOverlay__close}
          onClick={() => onClickClose()}
          aria-label="Close Contact Overlay">
          <span />
          <span />
        </button>
        <article className={styles.contactOverlay__section}>
          <h1
            className={styles.contactOverlay__title}
            id="contact-overlay-title"
            dangerouslySetInnerHTML={{ __html: stringToBold(get(contactTitle, 'content')) }}
          />
          <ul className={styles.contactOverlay__body}>
            {contactOptions.map((contact, index) => (
              <li key={index} className={styles.contactOverlay__body__item}>
                <h2
                  className={styles.contactOverlay__subTitle}
                  dangerouslySetInnerHTML={{ __html: get(contact, 'contactTitle') }}
                />

                {contact?.contactEmail ? (
                  <a
                    href={`mailto:${get(contact, 'contactEmail')}?subject=${
                      get(contact, 'contactEmail') === 'newbiz@codeandtheory.com'
                        ? 'New Business Inquiry [Source: Agency Website]'
                        : 'Press Inquiry [Source: Agency Website]'
                    }`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styles.contactOverlay__email}
                    aria-live="assertive"
                    aria-label={`${get(contact, 'contactEmail')}. Click to mail.`}>
                    <span>{get(contact, 'contactEmail')}</span>
                  </a>
                ) : null}

                {contact?.contactAddress?.content ? (
                  <p
                    className={styles.contactOverlay__address}
                    dangerouslySetInnerHTML={{ __html: get(contact, 'contactAddress.content') }}
                  />
                ) : null}

                {contact?.contactLink?.[0] &&
                Boolean(contact?.contactLink[0]?.title) &&
                Boolean(contact?.contactLink[0]?.uri) ? (
                  <span className={styles.contactOverlay__cta}>
                    <Link
                      to={{
                        pathname: get(contact, 'contactLink[0].uri'),
                        state: { componentLink: get(contact, 'contactComponentLink') }
                      }}
                      className={styles.contactOverlay__ctaLink}
                      title={get(contact, 'contactCtaTitle')}>
                      {get(contact, 'contactCtaTitle')}
                    </Link>
                  </span>
                ) : null}
              </li>
            ))}
            {newsletterSignup ? (
              <li className={styles.contactOverlay__newsletter}>
                <figure className={styles.contactOverlay__newsletter__logo}>
                  <Image
                    alt=""
                    src={get(newsletterSignup[0], 'newsletterSignupLogo[0].url')}
                    sizes={{ mobile: '200' }}
                  />
                </figure>
                <p className={styles.contactOverlay__newsletter__description}>
                  {get(newsletterSignup[0], 'newsletterSignupDescription')}
                </p>
                <Newsletter
                  className={styles.contactOverlay__newsletter__form}
                  isContactOpen={isOpen}
                  {...newsletterSignup[0]}
                  id="newsletter_contact"
                />
              </li>
            ) : null}
          </ul>
          {socialLinks.length || legalLinks.length ? (
            <footer className={styles.contactOverlay__footer}>
              {socialLinks.length ? (
                <nav className={styles.contactOverlay__socials} aria-label="Social Links">
                  {socialLinks.map((social, index) => (
                    <a
                      href={social.socialLinkUrl}
                      key={index}
                      className={styles.contactOverlay__social}
                      title={`Follow us on ${capitalize(get(social, 'socialLinkType'))}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={socialClick(get(social, 'socialLinkType'), 'footer social click')}>
                      <Icon name={get(social, 'socialLinkType')} />
                    </a>
                  ))}
                </nav>
              ) : null}

              {get(legalLinks, '[0]legalLinkEntry').length ? (
                <nav className={styles.contactOverlay__legal} aria-label="Legal Links">
                  {get(legalLinks[0], 'legalLinkEntry').map((link, index) => (
                    <Link to={`/${get(link, 'slug')}`} key={index} title={get(link, 'title')}>
                      {get(link, 'title')}
                    </Link>
                  ))}
                </nav>
              ) : null}
            </footer>
          ) : null}
        </article>
      </section>
    </CSSTransition>
  );
};

export default ContactOverlay;
