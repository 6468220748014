import React from 'react';
import { get, assign, find } from 'helpers/utilities';
import classNames from 'classnames/bind';
import { struct } from 'superstruct';

import Image from 'components/Image';
import Icon from 'components/Icon';
import CarouselVideo from 'components/CarouselVideo';
import safeRender from 'components/hocs/safeRender';
import NewsletterCard from 'components/NewsletterCard';

import { stripParagraphTag, replaceTagsToSpace } from 'helpers/text';
import { checkMediaType } from 'helpers/media';
import { toNYDateString } from 'helpers/date';
import { assetStruct, wysiwygStruct } from 'helpers/structure';

import { pickArticleHeaderContent } from 'queries/articleHeader';

import CardLink from './CardLink';
import styles from './styles.scss';

const cx = classNames.bind(styles);

const defaultImageSizes = {
  standard: {
    mobile: 750, // Mobile image can go up to 750px before going into grid layout on tablet
    tablet: 550
  },
  featured: {
    mobile: 750, // Mobile image can go up to 750px before going into grid layout on tablet
    tablet: 500,
    smallDesktop: 650,
    largeDesktop: 800
  },
  floating: {
    mobile: 700,
    tablet: 550
  }
};

export const getCardArtwork = (data, sizes) => {
  const types = {
    standard: 'standardCardArtwork',
    featured: 'featureCardArtwork',
    floating: 'floatingCardArtwork'
  };
  let card;
  let type;
  sizes.split('|').some(size => {
    card = get(data, `${types[size]}[0]`);
    type = size;
    return card;
  });

  if (type === 'featured' && checkMediaType(card.extension) === 'video') {
    const standardCard = get(data, 'standardCardArtwork[0]', {});
    return {
      videoURL: card.url,
      videoTitle: card.title,
      imageAlt: '',
      imageURL: standardCard.url,
      grayscale: data.grayscale || false,
      imageSizes: defaultImageSizes[type],
      type
    };
  }
  return {
    imageURL: card.url,
    imageAlt: '',
    grayscale: data.grayscale || false,
    imageSizes: defaultImageSizes[type],
    type
  };
};

export const renderCardArtwork = data => {
  const { imageURL, imageAlt, videoURL, videoClass, imageClass, imageSizes, grayscale } = data;
  return (
    <CarouselVideo
      imageURL={imageURL}
      imageAlt={imageAlt}
      videoURL={videoURL}
      videoClass={videoClass}
      imageClass={imageClass}
      imageSizes={imageSizes}
      grayscale={grayscale}
      playablePercentage={0.2}
    />
  );
};

const projectCardStruct = struct.partial({
  caption: 'string',
  description: 'string',
  featureCardArtwork: assetStruct,
  standardCardArtwork: assetStruct
});
const ProjectCard = React.forwardRef((props, ref) => {
  const {
    data,
    size = 'standard',
    className = '',
    onClick,
    style = {},
    zoom,
    onTransitionEndCapture
  } = props;
  const { caption, description, uri, textColor, backgroundColor, withBgColor } = data;
  const { type, ...card } = getCardArtwork(data, size);
  const cardStyles = cx('card', 'card--project', {
    is__inverted: textColor === 'blackText',
    'card--standard': type === 'standard' || type === 'floating',
    'card--featured': type === 'featured',
    'card--floating': type === 'floating',
    'card--zoom': zoom
  });

  return (
    <CardLink
      ref={ref}
      onClick={onClick}
      to={{ pathname: `/${uri}` }}
      className={`card ${cardStyles} ${className}`}
      title={`${caption} | ${description || ''}`}
      style={style}
      onTransitionEndCapture={onTransitionEndCapture}>
      <figure
        className={styles.card__background}
        style={{ backgroundColor: withBgColor ? get(backgroundColor, 'hex') : '' }}>
        {renderCardArtwork(card)}
      </figure>
      <section className={styles.card__inner}>
        <h2 className={styles.card__title}>
          <strong>{caption}</strong> | {description}
        </h2>
      </section>
      <aside aria-label={`Project card hover1 - ${caption}`} className={styles.card__hover} />
      <aside aria-label={`Project card hover2 - ${caption}`} className={styles.card__hover2} />
    </CardLink>
  );
});
const SafeProjectCard = safeRender(projectCardStruct)(ProjectCard);

const CuratedCard = React.forwardRef((props, ref) => {
  const {
    data,
    size = 'standard',
    className = '',
    onClick,
    active,
    style = {},
    zoom,
    onHover,
    onTransitionEndCapture
  } = props;
  const { caption, description, uri, textColor, backgroundColor, withBgColor, isExternalLink } =
    data;
  const { type, ...card } = getCardArtwork(data, size);
  const cardStyles = cx('card', 'card--curated', {
    is__inverted: textColor === 'blackText',
    'card--standard': type === 'standard' || type === 'floating',
    'card--featured': type === 'featured',
    'card--floating': type === 'floating',
    'card--inactive': active === false,
    'card--zoom': active && zoom
  });
  const hasCaption = caption && caption.length;

  return (
    <CardLink
      onClick={onClick}
      to={{ pathname: isExternalLink ? uri : `/${uri}` }}
      isExternalLink={isExternalLink}
      title={`${hasCaption ? `${caption} | ` : ''}${description || ''}`}
      className={`card ${cardStyles} ${className}`}
      style={style}
      ref={ref}
      onMouseOver={onHover}
      onFocus={onHover}
      onTransitionEndCapture={onTransitionEndCapture}>
      <figure
        className={styles.card__background}
        style={{ backgroundColor: withBgColor ? get(backgroundColor, 'hex') : '' }}>
        {renderCardArtwork(card)}
      </figure>
      <section className={styles.card__inner}>
        <h2
          className={styles.card__title}
          dangerouslySetInnerHTML={{
            __html: `${hasCaption && `<strong>${caption}</strong> | `}${description}`
          }}
        />
      </section>
      <aside aria-label="Curated card hover" className={styles.card__hover} />
      <aside className={styles.card__hover2} />
    </CardLink>
  );
});
const SafeCuratedCard = safeRender(projectCardStruct)(CuratedCard);

const articleCardStruct = struct.partial({
  caption: wysiwygStruct,
  featureCardArtwork: assetStruct,
  standardCardArtwork: assetStruct
});
const ArticleCard = React.forwardRef((props, ref) => {
  const {
    data,
    size = 'standard',
    className = '',
    onClick,
    style = {},
    zoom,
    onTransitionEndCapture
  } = props;
  const { caption, description, uri, textColor } = data;
  const { type, ...card } = getCardArtwork(data, size);
  const cardStyles = cx('card', 'card--article', {
    is__inverted: textColor === 'blackText',
    'card--standard': type === 'standard' || type === 'floating',
    'card--featured': type === 'featured',
    'card--floating': type === 'floating',
    'card--zoom': zoom
  });
  const showDescription = type !== 'feature' && description;

  return (
    <CardLink
      ref={ref}
      onClick={onClick}
      to={{ pathname: `/${uri}` }}
      title={replaceTagsToSpace(caption.content)}
      className={`card ${cardStyles} ${className}`}
      style={style}
      onTransitionEndCapture={onTransitionEndCapture}>
      <figure className={styles.card__background}>{renderCardArtwork(card)}</figure>
      <section className={`${styles.card__inner} ${showDescription ? styles.has__excerpt : ''}`}>
        <h2
          className={styles.card__title}
          dangerouslySetInnerHTML={{ __html: stripParagraphTag(caption.content) }}
        />
        {showDescription ? (
          <p className={styles.card__excerpt} dangerouslySetInnerHTML={{ __html: description }} />
        ) : (
          ''
        )}
      </section>
      <aside aria-label="Article card hover" className={styles.card__hover} />
      <aside className={styles.card__hover2} />
    </CardLink>
  );
});
const SafeArticleCard = safeRender(articleCardStruct)(ArticleCard);

const newsCardStruct = struct.partial({
  description: 'string'
});
const NewsCard = React.forwardRef((props, ref) => {
  const {
    data,
    size = 'standard',
    className = '',
    onClick,
    style = {},
    lazyload,
    onTransitionEndCapture,
    zoom
  } = props;
  const {
    title,
    uri,
    postDate,
    datedArticleType,
    datedArticleTitle,
    textColor,
    featureCardArtwork,
    standardCardArtwork,
    transparentCard,
    externalLink
  } = data;
  const featureCardData = featureCardArtwork.length ? get(featureCardArtwork, '[0]') : [];
  const standardCardData = standardCardArtwork.length ? get(standardCardArtwork, '[0]') : [];
  const hasMedia = featureCardArtwork.length || standardCardArtwork.length;
  const featuredMediaType = hasMedia ? checkMediaType(featureCardData.extension) : null;
  const rawDate = new Date(postDate * 1000);
  const publishDate = toNYDateString(rawDate);

  const cardStyles = cx('card', 'card--news', {
    is__inverted: textColor === 'blackText',
    'card--standard': size === 'standard',
    'card--featured': size === 'featured',
    'card--noBg': !hasMedia,
    'card--transparent-black': transparentCard === 'black',
    'card--zoom': zoom
  });

  const articleType = datedArticleType === 'ExternalContent' ? 'News' : datedArticleType;

  return (
    <CardLink
      ref={ref}
      onClick={onClick}
      to={{ pathname: externalLink || `/${uri}` }}
      isExternalLink={Boolean(externalLink)}
      title={title}
      className={`card ${cardStyles} ${className}`}
      style={style}
      onTransitionEndCapture={onTransitionEndCapture}>
      <figure className={styles.card__background}>
        {size === 'standard' ? (
          <Image
            src={standardCardData.url}
            alt=""
            grayscale={true}
            lazyload={lazyload}
            sizes={defaultImageSizes[size]}
          />
        ) : null}
        {size === 'featured' && featuredMediaType === 'image' ? (
          <Image
            src={featureCardData.url}
            alt=""
            grayscale={true}
            lazyload={lazyload}
            sizes={defaultImageSizes[size]}
          />
        ) : null}
      </figure>
      <section className={styles.card__inner}>
        <span className={styles.card__eyebrow}>
          <span
            style={{
              color: textColor === 'blackText' || 'rgba(255,255,255,0.6)'
            }}>
            {articleType}&nbsp;&mdash;&nbsp;
          </span>
          {articleType === 'Event' ? <Icon name="calendar" classes={styles.card__icon} /> : null}
          <time className={styles.card__date}>{publishDate}</time>
        </span>
        <h2
          className={styles.card__title}
          dangerouslySetInnerHTML={{ __html: stripParagraphTag(datedArticleTitle || '') }}
        />
      </section>
      <aside aria-label="News card hover" className={styles.card__hover} />
      <aside className={styles.card__hover2} />
    </CardLink>
  );
});
const SafeNewsCard = safeRender(newsCardStruct)(NewsCard);

const accountCardStruct = struct.partial({
  accountLogo: assetStruct,
  cardArtwork: assetStruct
});
const AccountCard = React.forwardRef(
  ({ data, className = '', onClick, style = {}, lazyload, onTransitionEndCapture }, ref) => {
    const { cardArtwork, accountLogo, uri, title } = data;
    const cardStyles = cx('card', 'card--account', 'card--standard', 'card--zoom');

    return (
      <CardLink
        ref={ref}
        onClick={onClick}
        to={{ pathname: `/${uri}` }}
        title={title}
        className={`card ${cardStyles} ${className}`}
        style={style}
        onTransitionEndCapture={onTransitionEndCapture}>
        <figure className={styles.card__background}>
          <Image
            src={get(cardArtwork, '[0].url')}
            alt={get(cardArtwork, '[0].title')}
            ariaHidden
            lazyload={lazyload}
          />
        </figure>
        <aside aria-label="Account card hover" className={styles.card__hover} />
        <aside className={styles.card__hover2} />
        <figure className={styles.card__inner}>
          <Image src={get(accountLogo, '[0].url')} alt="" lazyload={lazyload} />
        </figure>
      </CardLink>
    );
  }
);
const SafeAccountCard = safeRender(accountCardStruct)(AccountCard);

export const jobCard = React.forwardRef(
  ({ data, className = '', onClick, style = {}, onTransitionEndCapture }, ref) => {
    const { uri, title, jobLocation, jobTitle } = data;
    let jobCity;
    if (jobLocation) {
      jobCity = jobLocation.split(',');
    }
    const cardStyles = cx('card', 'card--job', 'card--standard', 'is__inverted');

    return (
      <CardLink
        onClick={onClick}
        to={{ pathname: `/${uri}` }}
        title={title}
        ref={ref}
        className={`card ${cardStyles} ${className}`}
        style={style}
        onTransitionEndCapture={onTransitionEndCapture}>
        <section className={styles.card__inner}>
          <span className={styles.card__eyebrow}>
            Job Opening<span> — {jobCity[0]}</span>
          </span>
          <h2 className={styles.card__title}>{jobTitle}</h2>
        </section>
        <aside aria-label="Job card hover" className={styles.card__hover} />
        <aside className={styles.card__hover2} />
      </CardLink>
    );
  }
);

export const NewsletterCardContainer = React.forwardRef(
  ({ data, className = '', onTransitionEndCapture }, ref) => {
    const { newsletterSignup } = data;
    const cardStyles = cx('card', 'card--newsletter', 'card--standard');

    return (
      <article
        ref={ref}
        className={`card ${cardStyles} ${className}`}
        onTransitionEndCapture={onTransitionEndCapture}>
        <aside aria-label="Newsletter card container hover" className={styles.card__hover} />
        <aside className={styles.card__hover2} />
        <section className={styles.card__inner}>
          <NewsletterCard newsletterSignup={newsletterSignup} />
        </section>
      </article>
    );
  }
);

// @TODO: Add all created cards components here
const cardTypes = {
  cardAccount: SafeAccountCard,
  cardArticle: SafeArticleCard,
  cardDetailedJob: jobCard,
  cardEvent: '',
  cardSaysALot: SafeNewsCard,
  cardPressRelease: '',
  cardProject: SafeProjectCard,
  cardSimpleJob: '',
  cardCurated: SafeCuratedCard,
  cardNewsletter: NewsletterCardContainer
};

const Card = (props, ref) => {
  const {
    title,
    size,
    uri,
    postDate,
    hideArtwork,
    className = '',
    onClick,
    style,
    withBgColor = true,
    transparentCard,
    lazyload = true,
    grayscale = false,
    active = true,
    zoom = false,
    imageSizes,
    isExternalLink,
    onHover = () => {},
    onTransitionEndCapture,
    externalLink
  } = props;
  const cardProps = props;
  const getType = find(Object.entries(cardTypes), type => cardProps[type[0]]);
  const cardName = get(getType, '[0]');
  const CardType = get(getType, '[1]');
  if (!CardType) return null;
  const initData = get(cardProps[cardName], '[0]');
  const datedArticleType = cardName === 'cardSaysALot' ? get(cardProps, '__typename') : null;
  const datedArticleTitle =
    cardName === 'cardSaysALot' ? pickArticleHeaderContent(cardProps) : null;
  const cardData = assign({}, initData, {
    title,
    uri,
    postDate,
    datedArticleType,
    datedArticleTitle,
    withBgColor,
    transparentCard,
    grayscale,
    imageSizes,
    isExternalLink,
    externalLink
  });
  return (
    <CardType
      ref={ref}
      data={cardData}
      hideArtwork={hideArtwork}
      size={size}
      className={className}
      onClick={onClick}
      style={style}
      lazyload={lazyload}
      active={active}
      zoom={zoom}
      onHover={onHover}
      onTransitionEndCapture={onTransitionEndCapture}
    />
  );
};

export default React.forwardRef(Card);
