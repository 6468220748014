import React, { useState, useEffect, useRef } from 'react';

import EventHandler from 'helpers/EventHandler';
import { EVENT } from 'config/constants';
import useDebounce from '../hooks/useDebounce';

const Orientation = React.createContext('landscape');

export const OrientationProvider = props => {
  const { children } = props;
  const debounce = useDebounce();

  const [orientation, setOrientation] = useState('landscape');

  const orientationRef = useRef('landscape');

  useEffect(() => {
    const resizeHandler = (_, e) => {
      const { width } = e;
      const height = window.innerHeight;

      const oldOrientation = orientationRef.current;
      const newOrientation = height < width ? 'landscape' : 'portrait';

      if (oldOrientation !== newOrientation) {
        orientationRef.current = newOrientation;
        setOrientation(orientationRef.current);
      }
    };
    const resiveEvent = EventHandler.subscribe(EVENT.RESIZE, debounce(resizeHandler, 500));

    return () => EventHandler.unsubscribe(resiveEvent);
  }, []);

  return <Orientation.Provider value={orientation}>{children}</Orientation.Provider>;
};

export default Orientation;
