import cardQueries from './cardQueries';

export default `
    ${cardQueries.article}
    ${cardQueries.project}
    ${cardQueries.curatedWork}
    ${cardQueries.account}
    ${cardQueries.event}
    ${cardQueries.press}
    ${cardQueries.news}
`;
