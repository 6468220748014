import React, { Fragment } from 'react';
import loadable from '@loadable/component';
import { Route, Switch } from 'react-router-dom';

import lazySizes from 'lazysizes';
import MainNavigation from './components/MainNavigation';
import OverlayNav from './components/OverlayNav';
import Transporter from './components/Transporter';

import ErrorHandler from './components/ErrorHandler';
import withHOCs from './components/withHOCs';
import GridGuide from './components/GridGuide';
import Meta from './components/Meta';

import { ReducedMotionProvider } from './contexts/ReducedMotion';
import { BreakpointProvider } from './contexts/Breakpoint';
import { AccessibilityProvider } from './contexts/Accessibility';
import { OrientationProvider } from './contexts/Orientation';
import { NavigationProvider } from './contexts/Navigation';
import { ScrollRestorationProvider } from './contexts/ScrollRestoration';
import { OverlayProvider } from './contexts/Overlay';
import { GlobalBackgroundProvider } from './contexts/GlobalBackground';

import 'lazysizes/plugins/attrchange/ls.attrchange';
import 'babel-polyfill';
import 'scroll-behavior-polyfill';
import 'ssr-intersection-observer';
import './styles/global.scss';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

lazySizes.cfg.expFactor = 4;

const fallback = () => null;
const Home = loadable(() => import(/* webpackChunkName: "CandtHome" */ './pages/Home'), {
  fallback
});
const WhyWeMake = loadable(
  () => import(/* webpackChunkName: "CandtWhyWeMake" */ './pages/WhyWeMake'),
  { fallback }
);
const ThingsWeMake = loadable(
  () => import(/* webpackChunkName: "CandtThingsWeMake" */ './pages/ThingsWeMake'),
  { fallback }
);
const TagPage = loadable(() => import(/* webpackChunkName: "CandtTagPage" */ './pages/TagPage'), {
  fallback
});
const Article = loadable(() => import(/* webpackChunkName: "CandtArticle" */ './pages/Article'), {
  fallback
});
const SaysALot = loadable(
  () => import(/* webpackChunkName: "CandtSaysALot" */ './pages/SaysALot'),
  { fallback }
);
const AboutUs = loadable(() => import(/* webpackChunkName: "CandtAboutUs" */ './pages/AboutUs'), {
  fallback
});
const Job = loadable(() => import(/* webpackChunkName: "CandtJob" */ './pages/Job'), { fallback });
const Careers = loadable(() => import(/* webpackChunkName: "CandtCareers" */ './pages/Careers'), {
  fallback
});
const Location = loadable(
  () => import(/* webpackChunkName: "CandtLocation" */ './pages/Location'),
  { fallback }
);
const CuratedPortfolioFile = loadable(
  () => import(/* webpackChunkName: "CandtCuratedPortfolioFile" */ './pages/CuratedPortfolioFile'),
  { fallback }
);
const CuratedPortfolioCaseStudy = loadable(
  () =>
    import(
      /* webpackChunkName: "CandtCuratedPortfolioCaseStudy" */ './pages/CuratedPortfolioCaseStudy'
    ),
  { fallback }
);
const CuratedPortfolio = loadable(
  () => import(/* webpackChunkName: "CandtCuratedPortfolio" */ './pages/CuratedPortfolio'),
  { fallback }
);
const InfoSinglePage = loadable(
  () => import(/* webpackChunkName: "CandtInfo" */ './pages/InfoSinglePage'),
  { fallback }
);

const App = () => (
  <>
    <MainNavigation />
    <OverlayNav />
    <Transporter>
      {({ active }) => (
        <Switch>
          <Route exact path="/(|home)" render={() => <Home active={active} />} />
          <Route exact path="/why-we-make" render={() => <WhyWeMake active={active} />} />
          <Route
            exact
            path="/things-we-make/filter/:tag"
            render={() => <TagPage active={active} />}
          />
          <Route exact path="/things-we-make/:project" render={() => <Article active={active} />} />
          <Route
            exact
            path="/things-we-make"
            render={() => <ThingsWeMake active={active} path="/things-we-make" />}
          />
          <Route
            exact
            path="/says-a-lot/:filter/:article"
            render={() => <Article active={active} />}
          />
          <Route exact path="/says-a-lot" render={() => <SaysALot active={active} />} />
          <Route exact path="/services/:service" render={() => <Article active={active} />} />
          <Route exact path="/about-us" render={() => <AboutUs active={active} />} />
          <Route exact path="/careers/:job" render={() => <Job active={active} />} />
          <Route exact path="/careers" render={() => <Careers active={active} />} />
          <Route exact path="/location/:location" render={() => <Location active={active} />} />
          <Route
            exact
            path="/new-business/:article/:filename/sign"
            render={() => <CuratedPortfolioFile active={active} />}
          />
          <Route
            exact
            path="/new-business/:article/:caseStudy"
            render={() => <CuratedPortfolioCaseStudy active={active} />}
          />
          <Route
            exact
            path="/new-business/:article"
            render={() => <CuratedPortfolio active={active} />}
          />
          <Route
            exact
            path="/search"
            render={() => <Meta active={active} gtm={{ type: 'Search' }} />}
          />
          <Route
            exact
            path="/contact"
            render={() => <Meta active={active} gtm={{ type: 'Contact' }} />}
          />
          <Route path="/*" render={() => <InfoSinglePage active={active} />} />
        </Switch>
      )}
    </Transporter>
    <GridGuide />
  </>
);

export default withHOCs(
  ErrorHandler,
  ReducedMotionProvider,
  BreakpointProvider,
  AccessibilityProvider,
  OrientationProvider,
  NavigationProvider,
  OverlayProvider,
  ScrollRestorationProvider,
  GlobalBackgroundProvider
)(App);
