import React from 'react';
import Imgix, { buildURL } from 'react-imgix';
import { breakpoints } from '../../config/constants';

/**
Breakpoints
    mobile: 360,
    tablet: 768,
    smallDesktop: 960,
    largeDesktop: 1248,
*/
const bpNames = Object.keys(breakpoints);

// Imgix, for some reason, loads the next larger image size for the current srcset match
const getNextBpName = bpName =>
  bpNames.includes(bpName) ? bpNames[bpNames.indexOf(bpName) + 1] : 'maxpoint';
const getNextBpSize = bpName =>
  bpNames.includes(bpName) ? breakpoints[getNextBpName(bpName)] : 1440;

const imageSizesDefault = {
  mobile: 750,
  tablet: 950,
  smallDesktop: 1200,
  largeDesktop: 1440
};

/**
 * Image Component
 *
 * @class      Image (name)
 * @param      {string}   src            The base url for the imgix image
 * @param      {string}   alt            Image alt
 * @param      {string}   className      Classname to be added into the img
 * @param      {Object}   style          Inline styles
 * @param      {Boolean}  lazyload       Whether to lazyload img
 * @param      {Boolean}  disableSrcSet  Whether to disable imgix's automatic
 *                                       srcset, in this case we are overriding
 *                                       imgix srcset
 * @param      {Boolean}  grayscale      Whether to apply grayscale filter on the image
 * @param      {Boolean}  invert         Whether to invert image colors
 * @param      {Number}   brightness     -100 to 100
 * @param      {Number}   width          Static width
 * @param      {Object}   sizes          Defines image size per breakpoint
 * @param      {String}   breakpoint     ?
 * @return     {Object}   JSX render
 */
const Image = React.forwardRef(
  (
    {
      src,
      alt,
      ariaHidden,
      className = '',
      style = {},
      imgixParams = {},
      disableSrcSet,
      grayscale = false,
      invert = false,
      brightness = 0,
      sizes,
      onClick,
      onFocus,
      tabIndex
    },
    ref
  ) => {
    const imgixParamsDefault = {
      sat: grayscale && grayscale === true ? -100 : 0,
      invert,
      bri: brightness,
      auto: 'compress,enhance,format',
      dpr: 1
    };
    const htmlAttributes = {
      alt,
      style,
      onClick,
      onFocus,
      tabIndex,
      ref
    };
    const srcSet = [];
    const imgixSizes = 'auto';
    let srcSetDisabled = disableSrcSet;

    if (!src) return null;

    // Use custom srcset if srcSetDisabled is false
    if (!srcSetDisabled) {
      const imageSizes = sizes instanceof Object ? sizes : imageSizesDefault;
      let width;
      Object.entries(imageSizes).forEach(([bpName, bpSize]) => {
        if (bpName in breakpoints) {
          const nextBpSize = getNextBpSize(bpName);

          if (!nextBpSize) return;
          const imgixParamsObj = imgixParams.length ? imgixParams : {};
          srcSet.push(
            `${buildURL(src, {
              w: bpSize,
              ...imgixParamsDefault,
              ...imgixParamsObj
            })} ${nextBpSize}w`
          );
          if (!width || width < bpSize) width = bpSize;
        }
      });
      imgixParamsDefault.w = width;
      imgixParams.w = width;
      htmlAttributes.srcSet = srcSet.join(', ');
      srcSetDisabled = true;
    }
    return (
      <Imgix
        key={src}
        className={`${className} lzy lazyload `}
        src={src}
        aria-hidden={ariaHidden}
        sizes={imgixSizes}
        attributeConfig={{
          src: 'data-src',
          srcSet: 'data-srcset',
          sizes: 'data-sizes'
        }}
        style={{ ...style }}
        imgixParams={{ ...imgixParamsDefault, ...imgixParams }}
        disableSrcSet={srcSetDisabled}
        htmlAttributes={htmlAttributes}
      />
    );
  }
);

export default Image;
