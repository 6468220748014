export const toNYDateString = date => {
  const NewYorkTZOffset = -240;

  const utc = date.getTime() + date.getTimezoneOffset() * 60000;
  const newDate = new Date(utc + 60000 * NewYorkTZOffset);

  return newDate.toLocaleString('en-US', { year: 'numeric', month: 'short', day: 'numeric' });
};

// Convert date to UTC date, mainly use with date-fns
// because it is not yet support UTC
export const getUTCDate = (dateString = Date.now()) => {
  const date = new Date(dateString);

  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds()
  );
};
