import React, { useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import { get } from 'helpers/utilities';
import { cleanURL } from 'helpers/text';
import canUseDOM from 'helpers/canUseDOM';
import useGlobalContent from '../../hooks/useGlobalContent';
import useAccesibility from '../../hooks/useAccessibility';

const Meta = ({ metaDetails, active, gtm = { type: '' } }) => {
  const globalContent = useGlobalContent();
  const { setCurrentScreenReaderMessage } = useAccesibility();
  const gtmSent = useRef(false);
  const globalMetaDetails = get(globalContent, 'globalMetaDetails.socialMetaSection[0]');

  const meta = {
    title:
      get(metaDetails, 'metaSection.metaTitle') ||
      (get(metaDetails, 'entryTitle')
        ? `${get(metaDetails, 'entryTitle')} - Code and Theory`
        : '') ||
      get(globalMetaDetails, 'metaTtile') ||
      'Code and Theory',
    description:
      get(metaDetails, 'metaSection.metaDescription') ||
      get(globalMetaDetails, 'metaDescription') ||
      'Code and Theory is a creative digital agency that designs products, content and campaigns across platforms.',
    imageURL:
      get(metaDetails, 'metaSection.metaImage[0].url') ||
      get(metaDetails, 'placeholderImage') ||
      get(globalMetaDetails, 'metaImage[0].url') ||
      `${window.location.origin}/images/candt-logo.jpg`,
    keywords:
      get(metaDetails, 'metaSection.metaKeywords') || get(globalMetaDetails, 'metaKeywords') || '',
    noIndex: get(metaDetails, 'noIndex', false)
  };

  useEffect(() => {
    if (active) setCurrentScreenReaderMessage(get(meta, 'title', '').split(' - ')[0]);
  }, [active, meta.title]);

  return active ? (
    <Helmet
      onChangeClientState={() => {
        if (!active || !gtm.type || !canUseDOM || gtmSent.current) return;
        gtmSent.current = true;
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: 'gtm.messagePageView',
          contentType: gtm.type,
          year: gtm.year,
          account: gtm.account,
          output: gtm.output,
          company: gtm.company,
          industry: gtm.industry
        });
      }}>
      {/* todo */}
      <html lang={get(globalContent, 'sites[0].language')} />
      <title>{meta.title}</title>
      <meta name="description" content={meta.description} />
      <meta name="keywords" content={meta.keywords || ''} />
      {meta.noIndex ? <meta name="robots" content="noindex, nofollow, noarchive" /> : null}

      {/* Opengraph Tags */}
      <meta property="og:title" content={meta.title} />
      <meta property="og:description" content={meta.description} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={cleanURL(window.location.href)} />
      <meta
        property="og:image"
        content={`${cleanURL(meta.imageURL)}?w=1200&auto=compress,format`}
      />
      <meta property="og:image:alt" content={cleanURL(meta.description)} />

      {/* Twitter card tags */}
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:site" content="@codeandtheory" />
      <meta property="twitter:title" content={meta.title} />
      <meta property="twitter:description" content={meta.description} />
      <meta
        property="twitter:image"
        content={`${cleanURL(meta.imageURL)}?w=1200&auto=compress,format`}
      />
      <meta property="twitter:image:alt" content={cleanURL(meta.description)} />
    </Helmet>
  ) : null;
};

export default Meta;
