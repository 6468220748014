import React, { useState, useRef } from 'react';
import classNames from 'classnames/bind';
import { stringToBold } from '../../helpers/text';

import styles from './styles.scss';
import { post } from '../../helpers/api';

const cx = classNames.bind(styles);
const sendNewsletter = post('/newsletter');

const Newsletter = ({
  isMinified,
  placeholder = 'Your Email Here',
  successMessage = 'Success! Thanks for signing up.',
  id,
  className
}) => {
  const focusedHelperText = 'Email Address';
  const unfocusedHelperText = isMinified ? 'Sign Up' : stringToBold('*Sign Up*');

  const [isFocus, setIsFocus] = useState(false);
  const [email, setEmail] = useState('');
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState('');
  const [helperText, setHelperText] = useState(unfocusedHelperText);
  const [isSubmitted, setIsSubmitted] = useState(false);

  // Refs
  const emailRef = useRef();

  const fieldClass = cx('newsletter__field', {
    'newsletter__field--open': isFocus || email,
    'newsletter__field--submitted': isSubmitted
  });

  function mouseOverHandler() {
    setIsFocus(true);
    setHelperText(focusedHelperText);
  }

  function mouseOutHandler() {
    setIsFocus(false);
    setHelperText(unfocusedHelperText);
  }

  function focusHandler() {
    setIsFocus(true);
    setHelperText(focusedHelperText);
  }

  function blurHandler() {
    setIsFocus(false);
    setHelperText(unfocusedHelperText);
  }

  function keyUpHandler(e) {
    setEmail(e.currentTarget.value);
  }

  function setupGAClickEvent(state) {
    if (id === 'newsletter_article') {
      window.dataLayer.push({
        event: 'newsletterSignupClick',
        state
      });
    }
  }

  function submitHandler(e) {
    e.preventDefault();

    const [, tagName] = window.location.pathname.split('/');
    const emailEl = emailRef.current;
    const invalidEmailMessage = 'Please enter a valid email address.';

    window.dataLayer = window.dataLayer || [];

    // remove validations
    setSuccess(false);
    setError('');

    // validations
    if (emailEl.validity.typeMismatch || emailEl.value === '') {
      setSuccess(false);
      setError(invalidEmailMessage);
      setIsSubmitted(true);
      setupGAClickEvent('fail');
      window.dataLayer.push({ event: 'newsletterFail' });
      return;
    }

    sendNewsletter({
      email: emailEl.value,
      tags: [tagName]
    })
      .then(() => {
        setSuccess(true);
        setError('');
        setIsSubmitted(true);
        setupGAClickEvent('success');
        window.dataLayer.push({ event: 'newsletterSuccess' });
      })
      .catch(err => {
        if (err.title === 'Member Exists') {
          // If the email is already registered, we want to show the success message.
          setSuccess(true);
          setError('');
          setIsSubmitted(true);
          setupGAClickEvent('success');
          window.dataLayer.push({ event: 'newsletterSuccess' });
          return;
        }

        const errorMessage = err.title === 'Invalid Resource' ? invalidEmailMessage : err.title;

        setSuccess(false);
        setError(errorMessage);
        setIsSubmitted(true);
        setupGAClickEvent('fail');
        window.dataLayer.push({ event: 'newsletterFail' });
      });
  }

  return (
    <form
      className={`newsletter ${styles.newsletter} ${className}`}
      onSubmit={submitHandler}
      autoComplete="off"
      noValidate>
      <div className={fieldClass}>
        <input
          type="email"
          ref={emailRef}
          id={id}
          className={styles.newsletter__email}
          placeholder={placeholder}
          onFocus={focusHandler}
          onBlur={blurHandler}
          onKeyUp={keyUpHandler}
          onMouseOver={mouseOverHandler}
          onMouseOut={mouseOutHandler}
          aria-label="Enter your email address to Sign Up for the Decode Newsletter from Code and Theory"
        />
        <label
          className={styles.newsletter__helper}
          htmlFor={id}
          dangerouslySetInnerHTML={{ __html: helperText }}
        />
        <button type="submit" className={styles.newsletter__submit} aria-label="Submit Email">
          <span className={styles.newsletter__icon} />
        </button>
        <div className={styles.newsletter__validations} role="alert">
          {error ? <span className={styles.newsletter__error}>{error}</span> : null}
          {success ? (
            <span
              className={styles.newsletter__success}
              dangerouslySetInnerHTML={{ __html: successMessage }}
            />
          ) : null}
        </div>
      </div>
    </form>
  );
};

export default Newsletter;
