export const handleResponse = async response => {
  if (!response.ok) {
    throw await response.json();
  }
  if (response.status === 204) {
    return Promise.resolve({});
  }
  return response.json();
};

export const post =
  (url, { headers = {} } = {}) =>
  params =>
    fetch(url, {
      method: 'POST',
      body: JSON.stringify(params),
      headers: {
        'Content-Type': 'application/json',
        ...headers
      }
    }).then(handleResponse);

export const get = (url, { headers = {} } = {}) =>
  fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      ...headers
    }
  }).then(handleResponse);
