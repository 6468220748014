import articleHeaders from './articleHeader';

const commonArticleContent = `
    uri
    title
    postDate
    cardSaysALot {
        ... on CardSaysALotCard {
            description
            textColor
            featureCardArtwork {
                url
                title
                extension
            }
            standardCardArtwork {
                url
                title
                extension
            }
        }
    }
`;

export default {
  externalContent: `
        ${articleHeaders.externalContent}
        ${commonArticleContent}
        externalLink
    `,
  news: `
        ${articleHeaders.news}
        ${commonArticleContent}
    `,
  event: `
        ${articleHeaders.event}
        ${commonArticleContent}
    `,
  press: `
        ${articleHeaders.press}
        ${commonArticleContent}
    `
};

export { commonArticleContent };
