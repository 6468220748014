import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';

const useEntryContent = (url, query, withLoading, withNoEntry) => {
  withNoEntry = withNoEntry ?? true;
  withLoading = withLoading ?? false;
  const { data = {}, loading } = useQuery(
    gql`
            query pageQuery($route: String!) {
                entry(uri: $route) {
                    title
                    type {
                        name
                        handle
                    }
                    postDate
                    url
                    uri
                    ${query}
                }
                sites (handle: "default") {
                    language
                }
            }
        `,
    { variables: { route: url } }
  );

  // force react to alert a component error to show 404 component
  if (withNoEntry && !withLoading && !loading && !data.entry) throw new Error('No Entry');

  if (withLoading) return { entry: data.entry, loading };
  return data.entry;
};

export default useEntryContent;
